import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Spinner, Alert, Table, Nav, Button, Modal, Form, InputGroup, FormControl, Tab, Tabs } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import config from '../../config';
import fetchWithAuth from '../../util/fetchWithAuth';
import { format } from 'date-fns';
import { UserContext } from '../../context/UserContext';

function ViewAllAccounts() {
  const [verifiedAccounts, setVerifiedAccounts] = useState([]);
  const [unverifiedAccounts, setUnverifiedAccounts] = useState([]);
  const [unverifiedAccountsToken, setUnverifiedAccountsToken] = useState([]);
  const [webRoles, setWebRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('verified');
  const [showModal, setShowModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [editField, setEditField] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [originalAccount, setOriginalAccount] = useState(null);
  const { username, roleId } = useContext(UserContext);
  const [showDeleteVerifiedModal, setShowDeleteVerifiedModal] = useState(false);
  const [showDeleteUnverifiedModal, setShowDeleteUnverifiedModal] = useState(false);
  const [showConfirmVerifyModal, setShowConfirmVerifyModal] = useState(false);
  const [showConfirmResendVerificationCodeModal, setShowConfirmResendVerificationCodeModal] = useState(false);
  const [isCreditsEditing, setIsCreditsEditing] = useState(false);
  const [reasonForCreditsChange, setReasonForCreditsChange] = useState('');
  const [isDeleteAction, setIsDeleteAction] = useState(false);




  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const itemsPerPageOptions = [20, 40, 60];

  const [creditsHistoryTab, setCreditsHistoryTab] = useState('all');
  const [showCreditsHistoryModal, setShowCreditsHistoryModal] = useState(false);
  const [voteHistory, setVoteHistory] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [tradeHistory, setTradeHistory] = useState([]);
  const [creditsHistoryPage, setCreditsHistoryPage] = useState(0);
  const [creditsHistoryItemsPerPage, setCreditsHistoryItemsPerPage] = useState(20);
  const [creditsHistorySearchTerm, setCreditsHistorySearchTerm] = useState('');

  const [voteHistoryPage, setVoteHistoryPage] = useState(0);
  const [purchaseHistoryPage, setPurchaseHistoryPage] = useState(0);
  const [tradeHistoryPage, setTradeHistoryPage] = useState(0);
  const [allHistoryPage, setAllHistoryPage] = useState(0);
  const [voteHistoryItemsPerPage, setVoteHistoryItemsPerPage] = useState(20);
  const [purchaseHistoryItemsPerPage, setPurchaseHistoryItemsPerPage] = useState(20);
  const [tradeHistoryItemsPerPage, setTradeHistoryItemsPerPage] = useState(20);
  const [allHistoryItemsPerPage, setAllHistoryItemsPerPage] = useState(20);

  useEffect(() => {
    fetchAllAccounts();
  }, []);

  const fetchAllAccounts = async () => {
    setLoading(true);
    setError('');
    try {
      const url = new URL(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.viewAllAccounts}`);
      const response = await fetchWithAuth(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          setVerifiedAccounts(jsonData.result.verifiedAccounts);
          setUnverifiedAccounts(jsonData.result.unverifiedAccounts);
          setUnverifiedAccountsToken(jsonData.result.unverifiedAccountsToken);
          setWebRoles(jsonData.result.webRoles);
        } else {
          setError(jsonData.result.message || 'Failed to fetch accounts');
        }
      } else {
        setError('Failed to fetch accounts');
      }
    } catch (error) {
      setError('Service is temporarily unavailable. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (account) => {
    setIsDeleteAction(false);  // Mark that this is NOT a delete action
    setEditField('');
    setIsCreditsEditing(false);
    setReasonForCreditsChange('');
    setSelectedAccount({ ...account, isPasswordChanged: false });
    setOriginalAccount({ ...account });
    setShowModal(true);
};

  const handleDelete = async (selectedAccountId, selectedRoleId, isVerified) => 
  {

    if(roleId < selectedRoleId)
    {
      setError('You do not have permission to delete this account.');
      return;
    }

    try {
      const url = new URL(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.deleteAccount}`);
      const response = await fetchWithAuth(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ targetAccountId: selectedAccountId, isVerified: isVerified }),
      });

      const jsonData = await response.json();
      const message = jsonData.result.message;

      if (jsonData.result.success) {
        setAlertMessage(message || 'Account deleted successfully');
        fetchAllAccounts();
        setCurrentPage(0);
      } else {
        setAlertMessage(message || 'Failed to delete account');
      }

      setAlertVisible(true);
    } catch (error) {
      setError('Service is temporarily unavailable. Please try again later.');
    }
  };

  const handleVerifyUser = async (accountId) => {
    const tokenEntry = unverifiedAccountsToken.find(entry => entry.accountId === accountId);

    if (!tokenEntry) {
      setError('No verification token found for this account.');
      return;
    }

    const token = tokenEntry.token;

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.overrideVerify}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const jsonData = await response.json();
      const message = jsonData.result.message;

      if (jsonData.result.success) {
        setAlertMessage(message || 'Verification successful');
      } else {
        setAlertMessage(message || 'Verification failed');
      }
      setAlertVisible(true);
      fetchAllAccounts();
      setCurrentPage(0);
    } catch (error) {
      setError('Service is temporarily unavailable. Please try again later.');
    }
  };

  const handleCloseCreditsHistoryModal = () => {
    // Reset all page states to 0 when the modal closes
    setAllHistoryPage(0);
    setVoteHistoryPage(0);
    setPurchaseHistoryPage(0);
    setTradeHistoryPage(0);
  
    // Close the modal
    setShowCreditsHistoryModal(false);
  };
  

  const handleResendVerificationCode = async (accountId) => {
    const tokenEntry = unverifiedAccountsToken.find(entry => entry.accountId === accountId);

    if (!tokenEntry) {
      setError('No verification token found for this account.');
      return;
    }

    const token = tokenEntry.token;

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.resendVerificationCode}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const jsonData = await response.json();
      const message = jsonData.result.message;

      if (jsonData.result.success) {
        setAlertMessage(message || 'Resend verification code successful');
      } else {
        setAlertMessage(message || 'Resend verification code failed');
      }
      setAlertVisible(true);
    } catch (error) {
      setError('Service is temporarily unavailable. Please try again later.');
    }
  };

  const updateAccountDetails = async () => {
    const updatedAccount = { ...selectedAccount };
    const password = updatedAccount.password !== originalAccount.password ? updatedAccount.password : originalAccount.password;

    const dataToSend = {
      id: updatedAccount.id,
      name: updatedAccount.name,
      password: password,
      isPasswordChanged: updatedAccount.isPasswordChanged,
      birthday: updatedAccount.birthday,
      email: updatedAccount.email,
      createdAt: updatedAccount.createdAt,
    };

    if (activeTab === 'verified') {
      dataToSend.credits = updatedAccount.credits;
      dataToSend.roleId = updatedAccount.roleId;      
      dataToSend.reasonForCreditsChange = reasonForCreditsChange;
      dataToSend.isVerified = true;
    } else {
      dataToSend.credits = null;
      dataToSend.roleId = null;
      dataToSend.isVerified = false;
    }

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updateAccount}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      const jsonData = await response.json();
      const message = jsonData.result.message;

      if (jsonData.result.success) {
        setAlertMessage(message || 'Account updated successfully');
        fetchAllAccounts();
        setShowModal(false);
      } else {
        setAlertMessage(message || 'Failed to update account');
      }
      setAlertVisible(true);
    } catch (error) {
      setError('Service is temporarily unavailable. Please try again later.');
    }
  };

  const handleViewCreditsHistory = async (account) => {
    setCreditsHistoryPage(0);
    setCreditsHistoryItemsPerPage(20);
    setCreditsHistorySearchTerm('');
    setCreditsHistoryTab('all');

    try {
      const url = new URL(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getTargetedCreditHistory}`);
      url.searchParams.append('accountId', account.id);
      url.searchParams.append('totalItems', 'all');

      const response = await fetchWithAuth(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const jsonData = await response.json();

        if (jsonData.result.success) {
          setVoteHistory(jsonData.result.voteHistory);
          setPurchaseHistory(jsonData.result.purchaseHistory);
          setTradeHistory(jsonData.result.creditTradeHistory);
          setShowCreditsHistoryModal(true);
        } else {
          setError(jsonData.result.message || 'Failed to fetch credits history');
        }
      } else {
        setError('Failed to fetch credits history');
      }
    } catch (error) {
      setError('Service is temporarily unavailable. Please try again later.');
    }
  };

  const formatDate = (timestamp) => {
    try {
      return format(new Date(timestamp), 'dd MMMM yyyy, HH:mm a');
    } catch (error) {
      console.error('Invalid date value:', timestamp);
      return 'Invalid date';
    }
  };

  const handleSort = () => {
    const sortedAccounts = [...verifiedAccounts].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setVerifiedAccounts(sortedAccounts);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSortUnverified = () => {
    const sortedAccounts = [...unverifiedAccounts].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setUnverifiedAccounts(sortedAccounts);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handlePageClick = ({ selected }) => {
    switch (creditsHistoryTab) {
      case 'all':
        setAllHistoryPage(selected);
        break;
      case 'vote':
        setVoteHistoryPage(selected);
        break;
      case 'purchase':
        setPurchaseHistoryPage(selected);
        break;
      case 'trade':
        setTradeHistoryPage(selected);
        break;
      default:
        break;
    }
  };

  const handleItemsPerPageChange = (e) => {
    const itemsPerPage = Number(e.target.value);
    switch (creditsHistoryTab) {
      case 'all':
        setAllHistoryItemsPerPage(itemsPerPage);
        setAllHistoryPage(0);
        break;
      case 'vote':
        setVoteHistoryItemsPerPage(itemsPerPage);
        setVoteHistoryPage(0);
        break;
      case 'purchase':
        setPurchaseHistoryItemsPerPage(itemsPerPage);
        setPurchaseHistoryPage(0);
        break;
      case 'trade':
        setTradeHistoryItemsPerPage(itemsPerPage);
        setTradeHistoryPage(0);
        break;
      default:
        break;
    }
  };

  const filteredVerifiedAccounts = verifiedAccounts.filter(account =>
    account.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredUnverifiedAccounts = unverifiedAccounts.filter(account =>
    account.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedVerifiedAccounts = filteredVerifiedAccounts.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const paginatedUnverifiedAccounts = filteredUnverifiedAccounts.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  
  const handleDeleteAccount = (account) => {
    setIsDeleteAction(true);  // Mark that this is a delete action
    setSelectedAccount(account);
    setShowDeleteVerifiedModal(true);
    //setSelectedAccount(account); setShowDeleteVerifiedModal(true);
  }

  const renderPagination = (pageCount) => {
    if (pageCount <= 1) return <nav className="pagination justify-content-center mt-3"><ul className="pagination"><li className="page-item active"><span className="page-link">1</span></li></ul></nav>;

    return (
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center mt-3'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        disabledClassName={''}
      />
    );
  };

  const renderTable = () => {
    const tableStyle = { tableLayout: 'fixed', width: '100%' };
    const hexStyle = { width: '3%' };
    const thStyle = { width: '10%' };
    const actionThStyle = { width: '30%' };

    if (activeTab === 'verified') {
      const pageCount = Math.ceil(filteredVerifiedAccounts.length / itemsPerPage);
      return paginatedVerifiedAccounts.length > 0 ? (
        <>
          <Table striped bordered hover responsive style={tableStyle}>
            <thead>
              <tr>
                <th style={hexStyle}>#</th>
                <th style={thStyle}>Name</th>
                <th style={{ ...thStyle, cursor: 'pointer' }} onClick={handleSort}>
                  Created At {sortOrder === 'asc' ? '▲' : '▼'}
                </th>
                <th style={actionThStyle}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedVerifiedAccounts.map((account, index) => (
                (roleId !== '3' || account.roleId !== '3') && (
                  <tr key={account.id}>
                    <td>{index + 1 + currentPage * itemsPerPage}</td>
                    <td>{account.name}</td>
                    <td>{formatDate(account.createdAt)}</td>
                    <td>
                      { 
                      account.name == username ? 
                      (
                        <>
                          <Button variant="info" onClick={() => handleViewCreditsHistory(account)}>View Credits History</Button>{' '}
                          <Button variant="warning" onClick={() => handleUpdate(account)}>Update</Button>{' '}
                        </>
                      ) : roleId == '2' && account.roleId != '4' && account.roleId == '1' || roleId == '2' && account.roleId != '3' && account.roleId == '1' ? (
                        <>          
                          <Button variant="info" onClick={() => handleViewCreditsHistory(account)}>View Credits History</Button>{' '}
                          <Button variant="warning" onClick={() => handleUpdate(account)}>Update</Button>{' '}
                          <Button variant="danger" onClick={() => { handleDeleteAccount(account) }}>Delete</Button>{' '}
                        </>
                      ) : roleId == '3' && account.roleId != '4' && account.roleId != '3' ? (
                        <>
                          <Button variant="info" onClick={() => handleViewCreditsHistory(account)}>View Credits History</Button>{' '}
                          <Button variant="warning" onClick={() => handleUpdate(account)}>Update</Button>{' '}
                          <Button variant="danger" onClick={() => { handleDeleteAccount(account) }}>Delete</Button>{' '}
                        </>
                      ): roleId == '4' ? (
                        <>
                          <Button variant="info" onClick={() => handleViewCreditsHistory(account)}>View Credits History</Button>{' '}
                          <Button variant="warning" onClick={() => handleUpdate(account)}>Update</Button>{' '}
                          <Button variant="danger" onClick={() => { handleDeleteAccount(account) }}>Delete</Button>{' '}
                        </>
                      ) : (
                        <>
                          {/* <Button variant="warning" onClick={() => handleUpdate(account)}>Update</Button>{' '} */}
                        </>
                      )}
                    </td>
                  </tr>
                )
              ))}
            </tbody>
          </Table>
          {renderPagination(pageCount)}
        </>
      ) : (
        <p className="text-center mt-3">No verified accounts</p>
      );
    } else if (activeTab === 'unverified') {
      const pageCount = Math.ceil(filteredUnverifiedAccounts.length / itemsPerPage);
      return paginatedUnverifiedAccounts.length > 0 ? (
        <>
          <Table striped bordered hover responsive style={tableStyle}>
            <thead>
              <tr>
                <th style={hexStyle}>#</th>
                <th style={thStyle}>Name</th>
                <th style={{ ...thStyle, cursor: 'pointer' }} onClick={handleSortUnverified}>
                  Created At {sortOrder === 'asc' ? '▲' : '▼'}
                </th>
                <th style={actionThStyle}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedUnverifiedAccounts.map((account, index) => (
                <tr key={account.id}>
                  <td>{index + 1 + currentPage * itemsPerPage}</td>
                  <td>{account.name}</td>
                  <td>{formatDate(account.createdAt)}</td>
                  <td>
                    {
                    roleId === '3' || roleId === '4' ?
                    <>
                      <Button variant="warning" onClick={() => handleUpdate(account)}>Update</Button>{' '}
                      <Button variant="danger" onClick={() => { setSelectedAccount(account); setShowDeleteUnverifiedModal(true); }}>Delete</Button>{' '}
                      <Button variant="secondary" onClick={() => { setSelectedAccount(account); setShowConfirmVerifyModal(true); }}>Verify</Button>{' '}
                      <Button variant="secondary" onClick={() => { setSelectedAccount(account); setShowConfirmResendVerificationCodeModal(true); }}>Resend Verification Code</Button>{' '}
                    </>
                    :
                    <>
                      <Button variant="secondary" onClick={() => { setSelectedAccount(account); setShowConfirmVerifyModal(true); }}>Verify</Button>{' '}
                      <Button variant="secondary" onClick={() => { setSelectedAccount(account); setShowConfirmResendVerificationCodeModal(true); }}>Resend Verification Code</Button>{' '}                    
                    </>
                    }
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {renderPagination(pageCount)}
        </>
      ) : (
        <p className="text-center mt-3">No unverified accounts</p>
      );
    }
  };

  const handleEditFieldChange = (field, value) => {
    if (field === 'credits') {
      setIsCreditsEditing(true);  // Start editing credits
    }

    setSelectedAccount({ ...selectedAccount, [field]: value });
  };

  const handleEditPasswordFieldChange = (field, value) => {
    setSelectedAccount({ ...selectedAccount, [field]: value, isPasswordChanged: true });
  };

  const resetChanges = () => {
    setReasonForCreditsChange('');  
    setSelectedAccount({ ...originalAccount });
    setEditField('');
  };

  const renderModalContent = () => {
    if (!selectedAccount || isDeleteAction) return null;

    const isUserRoleHigher = (loggedInRoleId, selectedAccountRoleId) => {
      return loggedInRoleId == '2' && selectedAccountRoleId == '3' || loggedInRoleId == '3' && selectedAccountRoleId == '4';
    };

    const getFilteredRoles = () => {
      return webRoles;
    };

    return (
      <>
        {Object.keys(selectedAccount).map((key) => (
          (key !== 'isPasswordChanged' && !(roleId === '2' && (key === 'id' || key === 'roleId'))) && (
            <Form.Group key={key} className="mb-3">
              <Form.Label>{key}</Form.Label>
              <Row>
                <Col>
                  {(() => {
                    switch (key) {
                      case 'id':
                      case 'createdAt':
                        return (
                          <Form.Control
                            type="text"
                            value={key === 'createdAt' ? formatDate(selectedAccount[key]) : selectedAccount[key]}
                            readOnly
                          />
                        );
                      case 'birthday':
                        return (
                          <Form.Control
                            type="date"
                            value={format(new Date(selectedAccount[key]), 'yyyy-MM-dd')}
                            onChange={(e) => handleEditFieldChange(key, e.target.value)}
                            readOnly={editField !== key}
                          />
                        );
                      case 'name':
                      case 'email':
                        return (
                          <Form.Control
                            type={key === 'email' ? 'email' : 'text'}
                            value={selectedAccount[key]}
                            onChange={(e) => handleEditFieldChange(key, e.target.value)}
                            readOnly={editField !== key}
                          />
                        );
                      case 'password':
                        return editField === key ? (
                          <Form.Control
                            type="text"
                            value={selectedAccount[key] === originalAccount[key] ? '' : selectedAccount[key]}
                            onChange={(e) => handleEditPasswordFieldChange(key, e.target.value)}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            value={selectedAccount[key]}
                            readOnly
                          />
                        );
                      case 'roleId':
                        return (
                          <Form.Control
                            as="select"
                            value={selectedAccount[key]}
                            onChange={(e) => handleEditFieldChange(key, e.target.value)}
                            disabled={editField !== key}
                          >
                            {getFilteredRoles().map((role) => (
                              <option key={role.id} value={role.id}>
                               {role.id} - {role.name}
                              </option>
                            ))}
                          </Form.Control>
                        );
                        case 'credits':
                          const creditDifference = selectedAccount[key] - originalAccount[key]; // Calculate the credit difference

                          return (
                            <div>
                              <Form.Control
                                type="number"
                                value={selectedAccount[key]}
                                onChange={(e) => handleEditFieldChange(key, e.target.value)}
                                readOnly={editField !== key}
                                />
                              {isCreditsEditing && (
                                <div className="mt-2">
                                  <Form.Label>Reason for changing credits</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter reason for updating credits"
                                    value={reasonForCreditsChange}
                                    onChange={(e) => setReasonForCreditsChange(e.target.value)}
                                  />
                                  <div className="mt-2">
                                    <Form.Text>
                                      {creditDifference >= 0
                                        ? `Credit Change: +${creditDifference.toLocaleString()}`
                                        : `Credit Change: ${creditDifference.toLocaleString()}`}
                                    </Form.Text>
                                  </div>
                                </div>
                              )}
                            </div>
                          );

                      default:
                        return null;
                    }
                  })()}
                </Col>
                <Col md="auto">
                  {key != 'id' && key !== 'createdAt' && !isUserRoleHigher(roleId, selectedAccount.roleId) && (
                    (roleId == '2' && !['name', 'credits', 'roleId'].includes(key)) || (roleId == '3' && selectedAccount.roleId != '4' && !['roleId'].includes(key)) || roleId == '4' ? (
                      editField == key ? (
                        <Button variant="primary" onClick={() => setEditField('')}>Save</Button>
                      ) : (
                        <Button variant="secondary" onClick={() => setEditField(key)}>Edit</Button>
                      )
                    ) : null
                  )}
                </Col>
              </Row>
            </Form.Group>
          )
        ))}
      </>
    );
  };

  const renderCreditsHistoryModalContent = () => {
    const handlePageClick = ({ selected }) => {
      switch (creditsHistoryTab) {
        case 'all':
          setAllHistoryPage(selected);
          break;
        case 'vote':
          setVoteHistoryPage(selected);
          break;
        case 'purchase':
          setPurchaseHistoryPage(selected);
          break;
        case 'trade':
          setTradeHistoryPage(selected);
          break;
        default:
          break;
      }
    };

    const handleItemsPerPageChange = (e) => {
      const itemsPerPage = Number(e.target.value);
      switch (creditsHistoryTab) {
        case 'all':
          setAllHistoryItemsPerPage(itemsPerPage);
          setAllHistoryPage(0);
          break;
        case 'vote':
          setVoteHistoryItemsPerPage(itemsPerPage);
          setVoteHistoryPage(0);
          break;
        case 'purchase':
          setPurchaseHistoryItemsPerPage(itemsPerPage);
          setPurchaseHistoryPage(0);
          break;
        case 'trade':
          setTradeHistoryItemsPerPage(itemsPerPage);
          setTradeHistoryPage(0);
          break;
        default:
          break;
      }
    };

    const handleSearchTermChange = (e) => {
      setCreditsHistorySearchTerm(e.target.value);
      setCreditsHistoryPage(0);
    };

    const filterBySearchTerm = (data) => {
      if (!creditsHistorySearchTerm) return data;
      return data.filter(item =>
        formatDate(item.debitedDate).toLowerCase().includes(creditsHistorySearchTerm.toLowerCase())
      );
    };

    const paginateData = (data) => {
      const currentPage = {
        all: allHistoryPage,
        vote: voteHistoryPage,
        purchase: purchaseHistoryPage,
        trade: tradeHistoryPage,
      }[creditsHistoryTab] || 0;
    
      const itemsPerPage = {
        all: allHistoryItemsPerPage,
        vote: voteHistoryItemsPerPage,
        purchase: purchaseHistoryItemsPerPage,
        trade: tradeHistoryItemsPerPage,
      }[creditsHistoryTab] || 20;
    
      const start = currentPage * itemsPerPage;
      const end = start + itemsPerPage;
      return data.slice(start, end);
    };
    

    const renderPagination = (pageCount) => {
      if (pageCount <= 1) return null; // Don't render pagination if there's only one or zero pages
      return (
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination justify-content-center mt-3'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          activeClassName={'active'}
          disabledClassName={''}
        />
      );
    };
    

    const renderTableWithPagination = (data, renderTableContent) => {
      const filteredData = filterBySearchTerm(data);
      const paginatedData = paginateData(filteredData);
      
      // Calculate page count based on the filtered data
      const itemsPerPage = {
        all: allHistoryItemsPerPage,
        vote: voteHistoryItemsPerPage,
        purchase: purchaseHistoryItemsPerPage,
        trade: tradeHistoryItemsPerPage,
      }[creditsHistoryTab] || 20;
      
      const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    
      // Only render pagination if there is data and more than 1 page
      return (
        <>
          {renderTableContent(paginatedData, filteredData)}
          {filteredData.length > 0 && pageCount > 1 && renderPagination(pageCount)}
        </>
      );
    };
    
    

    const renderVoteHistory = (data, fullData) => (
      data.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Status</th>
              <th>Credits</th>
              <th>IP</th>
              <th>Comments</th>
              <th>Approved By</th>
              <th>Voted Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((vote, index) => (
              <tr key={`vote-${vote.id}`}>
                <td>{index + 1 + voteHistoryPage * voteHistoryItemsPerPage}</td>
                <td>{vote.transactionStatus}</td>
                <td>{vote.creditAmount}</td>
                <td>{vote.ip}</td>
                <td>{vote.reason}</td>
                <td>{vote.approvedBy}</td>
                <td>{formatDate(vote.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center mt-3">No vote history</p>
      )
    );

    const renderPurchaseHistory = (data, fullData) => (
      data.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Purchased Gateway</th>
              <th>Purchased Price</th>
              <th>Credits Purchased</th>
              <th>Payment Status</th>
              <th>Unique Code</th>
              <th>Comments</th>
              <th>Updated By</th>
              <th>Purchase Date</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {data.map((purchase, index) => (
              <tr key={`purchase-${purchase.id}`}>
                <td>{index + 1 + purchaseHistoryPage * purchaseHistoryItemsPerPage}</td>
                <td>{purchase.paymentGateway}</td>
                <td>{purchase.price.toFixed(2)} SGD</td>
                <td>{purchase.credits.toLocaleString()} credits</td>
                <td>{purchase.paymentStatus}</td>
                <td>{purchase.uniqueCode}</td>
                <td>{purchase.reason}</td>
                <td>{purchase.updatedBy}</td>
                <td>{formatDate(purchase.createdAt)}</td>
                <td>{formatDate(purchase.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center mt-3">No purchase history</p>
      )
    );

    const renderTradeHistory = (data, fullData) => (
      data.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Credits Used</th>
              <th>Currency Amount</th>
              <th>Game</th>
              <th>Currency Type</th>
              <th>Currency Rates</th>
              <th>Traded Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((trade, index) => (
              <tr key={`trade-${trade.id}`}>
                <td>{index + 1 + tradeHistoryPage * tradeHistoryItemsPerPage}</td>
                <td>{`-${trade.credits.toLocaleString()} credits`}</td>
                <td>{`${trade.currencyAmount.toLocaleString()} ${trade.currencyType}`}</td>
                <td>{trade.destination}</td>
                <td>{trade.currencyType}</td>
                <td>{trade.currencyRates}</td>
                <td>{formatDate(trade.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center mt-3">No trade history</p>
      )
    );

    const renderAllHistory = (data, fullData) => (
      data.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Credits</th>
              <th>Transaction Date</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={`all-${item.id}-${item.source}`}>
                <td>{index + 1 + allHistoryPage * allHistoryItemsPerPage}</td>
                <td>{item.credits}</td>
                <td>{formatDate(item.debitedDate)}</td>
                <td>{item.source}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center mt-3">No history</p>
      )
    );

    const handleTabSelect = (tab) => {
      setCreditsHistoryTab(tab);
      switch (tab) {
        case 'all':
          setCreditsHistoryPage(allHistoryPage);
          break;
        case 'vote':
          setCreditsHistoryPage(voteHistoryPage);
          break;
        case 'purchase':
          setCreditsHistoryPage(purchaseHistoryPage);
          break;
        case 'trade':
          setCreditsHistoryPage(tradeHistoryPage);
          break;
        default:
          break;
      }
    };
    

    const allData = [
      ...voteHistory.filter((vote) => vote.transactionStatus === 'APPROVED').map((vote, index) => ({
        id: vote.id,
        credits: vote.creditAmount,
        debitedDate: vote.createdAt,
        source: 'Vote',
        key: index + 1,
      })),
      ...purchaseHistory.filter((purchase) => purchase.paymentStatus === 'COMPLETED').map((purchase, index) => ({
        id: purchase.id,
        credits: purchase.credits,
        debitedDate: purchase.updatedAt,
        source: 'Purchase',
        key: index + 1 + voteHistory.length,
      })),
      ...tradeHistory.map((trade, index) => ({
        id: trade.id,
        credits: `-${trade.credits.toLocaleString()}`,
        debitedDate: trade.createdAt,
        source: trade.destination,
        key: index + 1 + voteHistory.length + purchaseHistory.length,
      })),
    ].sort((a, b) => b.debitedDate - a.debitedDate);

    return (
      <>
        <InputGroup className="mb-3">
          <FormControl
            type="text"
            value={creditsHistorySearchTerm}
            onChange={handleSearchTermChange}
            placeholder="Search by Date"
          />
        </InputGroup>
        <Tabs
          id="credits-history-tabs"
          activeKey={creditsHistoryTab}
          onSelect={handleTabSelect}
          className="mb-3"
        >
          <Tab eventKey="all" title="All History">
            {renderTableWithPagination(allData, renderAllHistory)}
          </Tab>
          <Tab eventKey="vote" title="Vote History">
            {renderTableWithPagination(voteHistory, renderVoteHistory)}
          </Tab>
          <Tab eventKey="purchase" title="Purchase History">
            {renderTableWithPagination(purchaseHistory, renderPurchaseHistory)}
          </Tab>
          <Tab eventKey="trade" title="Trade History">
            {renderTableWithPagination(tradeHistory, renderTradeHistory)}
          </Tab>
        </Tabs>
      </>
    );
  };

  return (
    <Container className="mt-5">
      <h1 className="red-text">View All Accounts</h1>
      {loading ? (
        <Spinner animation="border" role="status" className="d-block mx-auto">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : (
        <>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedTab) => setActiveTab(selectedTab)}>
            <Nav.Item>
              <Nav.Link eventKey="verified">Verified Accounts</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="unverified">Unverified Accounts</Nav.Link>
            </Nav.Item>
          </Nav>

          {alertVisible && (
            <Alert variant="success" onClose={() => setAlertVisible(false)} dismissible>
              {alertMessage}
            </Alert>
          )}

          <InputGroup className="mb-3">
            <FormControl
              placeholder="Search by name"
              aria-label="Search by name"
              aria-describedby="basic-addon2"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Show items:</Form.Label>
            <Col sm="10">
              <Form.Control as="select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                {itemsPerPageOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          {renderTable()}

          <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Update Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderModalContent()}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="danger" onClick={resetChanges}>
                Reset
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (isCreditsEditing && !reasonForCreditsChange) {
                    setError('Please enter a reason for changing credits');
                    return;
                  }
                  updateAccountDetails();
                }}
                disabled={isCreditsEditing && !reasonForCreditsChange}  // Disable if credits changed but no reason
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showDeleteVerifiedModal} onHide={() => setShowDeleteVerifiedModal(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Confirm deletion of verification account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete <b>{selectedAccount && selectedAccount.name}</b>?</p> <p className="text-danger">Please note that this action is irreversable and all account data will be wiped from the server.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteVerifiedModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => { handleDelete(selectedAccount.id, selectedAccount.roleId, true); setShowDeleteVerifiedModal(false); }}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showDeleteUnverifiedModal} onHide={() => setShowDeleteUnverifiedModal(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Confirm deletion of unverification account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete <b>{selectedAccount && selectedAccount.name}</b>?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteUnverifiedModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => { handleDelete(selectedAccount.id, selectedAccount.roleId, false); setShowDeleteUnverifiedModal(false); }}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showConfirmVerifyModal} onHide={() => setShowConfirmVerifyModal(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Confirm verification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to verify <b>{selectedAccount && selectedAccount.name}</b>?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowConfirmVerifyModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => { handleVerifyUser(selectedAccount.id); setShowConfirmVerifyModal(false); }}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showConfirmResendVerificationCodeModal} onHide={() => setShowConfirmResendVerificationCodeModal(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Confirm resend verification code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to resend verification code for <b>{selectedAccount && selectedAccount.name}</b>?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowConfirmResendVerificationCodeModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => { handleResendVerificationCode(selectedAccount.id); setShowConfirmResendVerificationCodeModal(false); }}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showCreditsHistoryModal} onHide={() => setShowCreditsHistoryModal(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Credits History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {renderCreditsHistoryModalContent()}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCreditsHistoryModal}>Close</Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Container>
  );
}

export default ViewAllAccounts;
