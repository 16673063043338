import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Spinner, Alert, Form, Row, Col } from 'react-bootstrap';
import config from '../../config';
import { useExecuteRecaptcha } from '../../util/ReCaptcha';

function Verify() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { execute } = useExecuteRecaptcha();

  const handleVerify = async () => {
    setLoading(true);
    setIsButtonDisabled(true);
    setError('');
    setMessage('');

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    try {
      const reCaptchaToken = await execute('resetpassword');

      const response = await fetch(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.verify}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          reCaptchaToken: reCaptchaToken
        }),
      });

      const jsonData = await response.json();
      const message = jsonData.result.message;

      if (response.ok) {
        if (jsonData.result.success === true) {
          var successMessage = message + " Redirecting to login page...";
          setMessage(successMessage);
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } else {
          setError(message || 'Verification failed. Please try again.');
          setIsButtonDisabled(true);
        }
      } else {
        setError(message || 'Verification failed. Please try again.');
        setIsButtonDisabled(true);
      }
    } catch (error) {
      setError('Network error. Please try again later.');
      setIsButtonDisabled(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={6}>
          {loading ? (
            <Spinner animation="border" role="status" className="d-block mx-auto">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Form>
              <Alert variant="info">
                <p>To continue with the verification, please click the button below:</p>
              </Alert>
              <div className="d-grid gap-2 mb-3">
                <Button variant="primary" onClick={handleVerify} disabled={isButtonDisabled}>
                  Continue to Verify
                </Button>
              </div>
              {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="success">{message}</Alert>}
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Verify;
