import React, { useState } from 'react';
import { Container, Button, Spinner, Alert, Form, Modal } from 'react-bootstrap';
import config from '../../config';
import fetchWithAuth from '../../util/fetchWithAuth';

function MythicMSv83AdminPanel() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [characterName, setCharacterName] = useState('');
  const [gmLevel, setGmLevel] = useState(0);
  const [secondaryPassword, setSecondaryPassword] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [actionType, setActionType] = useState('');

  const handleCloseModal = () => setShowPasswordModal(false);
  const handleShowModal = (action) => {
    setActionType(action);
    setShowPasswordModal(true);
  };

  const handleConfirmAction = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    handleCloseModal();

    try {
      let endpoint = '';
      let body = {};
      
      switch (actionType) {
        case 'truncateDatabase':
          endpoint = `${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.truncateMythicMSv83Database}`;
          body = { secondaryPassword };
          break;
        case 'unstuckCharacter':
          endpoint = `${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updateMythicMSv83UnstuckCharacter}`;
          body = { characterName, secondaryPassword };
          break;
        case 'setGmLevel':
          endpoint = `${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updateMythicMSv83CharacterToGmLevel}`;
          body = { characterName, gmLevel, secondaryPassword };
          break;
        default:
          break;
      }

      const response = await fetchWithAuth(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) {
        setSuccess(jsonData.result.message || 'Operation successful');
      } else {
        setError(jsonData.result.message || 'Operation failed');
      }
    } catch (error) {
      setError('Network error');
    } finally {
      setLoading(false);
      setSecondaryPassword('');
    }
  };

  return (
    <Container className="mythicms-admin-panel-container">
      <div className="mythicms-admin-panel-card">
        <h1>MythicMS v83 Admin Panel</h1>
        {loading ? (
          <Spinner animation="border" role="status" className="d-block mx-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <Button
              variant="danger"
              className="mt-4"
              onClick={() => handleShowModal('truncateDatabase')}
            >
              Truncate MythicMS v83 Database
            </Button>

            <hr /> {/* Horizontal ruler below Truncate button */}

            {/* Unstuck Character Section */}
            <Form className="mt-4">
              <Form.Group>
                <Form.Label>Character Name (Unstuck)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter character name"
                  value={characterName}
                  onChange={(e) => setCharacterName(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                className="mt-2"
                onClick={() => handleShowModal('unstuckCharacter')}
              >
                Unstuck Character
              </Button>
            </Form>

            <hr /> {/* Another horizontal ruler */}

            {/* Set GM Level Section */}
            <Form className="mt-4">
              <Form.Group>
                <Form.Label>Character Name (Set GM Level)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter character name"
                  value={characterName}
                  onChange={(e) => setCharacterName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>GM Level</Form.Label>
                <Form.Control
                  as="select"
                  value={gmLevel}
                  onChange={(e) => setGmLevel(Number(e.target.value))}
                >
                  {[...Array(7).keys()].map((level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button
                variant="success"
                className="mt-2"
                onClick={() => handleShowModal('setGmLevel')}
              >
                Set GM Level
              </Button>
            </Form>

            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">{success}</Alert>}
          </>
        )}

        {/* Modal for Secondary Password */}
        <Modal show={showPasswordModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Secondary Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Secondary Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter secondary password"
                value={secondaryPassword}
                onChange={(e) => setSecondaryPassword(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmAction}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
}

export default MythicMSv83AdminPanel;