import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Modal, Alert } from 'react-bootstrap';
import fetchWithAuth from '../../../util/fetchWithAuth';
import config from '../../../config';
import ReactPaginate from 'react-paginate'; // Import for pagination

function PurchaseCreditsDetails() {
  const [creditDetails, setCreditDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newCredit, setNewCredit] = useState({ price: '', credits: '' });
  const [editingCreditId, setEditingCreditId] = useState(null); // To track the credit being edited
  const [editedCredit, setEditedCredit] = useState({ price: '', credits: '' }); // Store the edited credit details
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const [itemsPerPage] = useState(20); // Number of items per page
  const [error, setError] = useState(''); // State to hold error messages
  const [showErrorModal, setShowErrorModal] = useState(false); // Control error modal

  useEffect(() => {
    fetchCreditDetails();
  }, []);

  const fetchCreditDetails = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getCreditDetails}`);
      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          const creditDetailsObject = jsonData.result.data;

          // Convert the creditDetailsObject into an array
          const creditDetailsArray = Object.entries(creditDetailsObject).map(([id, details]) => ({
            id,
            ...details,
          }));

          setCreditDetails(creditDetailsArray);
        } else {
          throw new Error(jsonData.result.message || 'Error fetching credit details');
        }
      } else {
        throw new Error('Error fetching credit details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleError = (message) => {
    setError(message);
    setShowErrorModal(true);
  };

  const handleCloseCreditDialogue = () => {
    setShowModal(false);
    setNewCredit({ price: '', credits: '' });
  };

  const handleAddCredit = async () => {    
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.addCreditDetails}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          price: newCredit.price,
          credits: newCredit.credits,
        }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          fetchCreditDetails(); // Refresh list after adding
          setShowModal(false); // Close the modal after adding
          setNewCredit({ price: '', credits: '' }); // Reset the form
        } else {
          handleError(jsonData.result.message || 'Error adding credit details');
        }
      } else {
        throw new Error('Error adding credit details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleUpdateCredit = (credit) => {
    setEditingCreditId(credit.id);
    setEditedCredit({ price: credit.price, credits: credit.credits });
  };

  const handleSaveUpdate = async (id) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updateCreditDetails}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          price: editedCredit.price,
          credits: editedCredit.credits,
        }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          fetchCreditDetails(); // Refresh list after saving
          setEditingCreditId(null); // Exit editing mode
        } else {
          handleError(jsonData.result.message || 'Error updating credit details');
        }
      } else {
        throw new Error('Error updating credit details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleCancelEdit = () => {
    setEditingCreditId(null); // Cancel the editing process
  };

  const handleRemoveCredit = async (id, price, credits) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.deleteCreditDetails}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          id,
          credits: credits,
          price: price,
        }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          fetchCreditDetails(); // Refresh list after deletion
        } else {
          handleError(jsonData.result.message || 'Error deleting credit details');
        }
      } else {
        throw new Error('Error deleting credit details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const paginatedCreditDetails = creditDetails.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div>
      <Button
        onClick={() => setShowModal(true)}
        variant="primary"
        style={{ marginTop: '20px', marginBottom: '20px' }} // Spacing for the button
      >
        Add Credit Details
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Price (SGD)</th>
            <th>Credits</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedCreditDetails.length > 0 ? (
            paginatedCreditDetails.map((credit) => (
              <tr key={credit.id}>
                {editingCreditId === credit.id ? (
                <>
                  <td>
                    <Form.Control
                      type="number"
                      step="0.01" // Ensures decimal format with two decimal places
                      placeholder="Enter price"
                      value={editedCredit.price}
                      onChange={(e) => setEditedCredit({ ...editedCredit, price: e.target.value })}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      step="1" // Ensures only whole numbers
                      placeholder="Enter credits"
                      value={editedCredit.credits}
                      onChange={(e) => setEditedCredit({ ...editedCredit, credits: e.target.value })}
                    />
                  </td>
                  <td>
                    <Button variant="success" onClick={() => handleSaveUpdate(credit.id)}>
                      Save
                    </Button>
                    <Button variant="secondary" onClick={handleCancelEdit} style={{ marginLeft: '10px' }}>
                      Cancel
                    </Button>
                  </td>
                </>
              ) : (
                <>
                  <td>{credit.price}</td>
                  <td>{credit.credits}</td>
                  <td>
                    <Button
                      variant="warning"
                      style={{ marginRight: '10px' }}
                      onClick={() => handleUpdateCredit(credit)}
                    >
                      Update
                    </Button>
                    <Button variant="danger" onClick={() => handleRemoveCredit(credit.id, credit.price, credit.credits)}>
                      Remove
                    </Button>
                  </td>
                </>
              )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center">
                No credit details available
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination */}
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={Math.ceil(creditDetails.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center mt-3'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        disabledClassName={'disabled'}
      />

      {/* Modal for Adding Credit */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Credit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="price">
              <Form.Label>Price (SGD)</Form.Label>
              <Form.Control
                type="number"
                step="0.01" // Allows decimals with two places
                placeholder="Enter price"
                value={newCredit.price}
                onChange={(e) => setNewCredit({ ...newCredit, price: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="credits">
              <Form.Label>Credits</Form.Label>
              <Form.Control
                type="number"
                step="1" // Allows only whole numbers
                placeholder="Enter credits"
                value={newCredit.credits}
                onChange={(e) => setNewCredit({ ...newCredit, credits: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreditDialogue}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddCredit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">{error}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PurchaseCreditsDetails;
