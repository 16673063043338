import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';
import config from '../../config';
import { validatePassword } from '../../util/PasswordValidation';
import { useExecuteRecaptcha } from '../../util/ReCaptcha';

function ResetPassword() {
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { execute } = useExecuteRecaptcha();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    setToken(token);
  }, [location.search]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');

    try {      
      const reCaptchaToken = await execute('resetpassword');

      const response = await fetch(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.resetpassword}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          token: token, 
          password: password, 
          confirmPassword: confirmPassword,
          reCaptchaToken: reCaptchaToken 
        }),
      });

      const jsonData = await response.json();
      const message = jsonData.result.message;

      if (response.ok) {
        var successMessage = message + ' Redirecting to login page...';
        setMessage(successMessage);
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        setError('Failed to reset password. Please try again.');
      }
    } catch (error) {
      setError('Network error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h1>Reset Password</h1>
      <Form onSubmit={handleResetPassword}>
        <Form.Group controlId="formPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formConfirmPassword">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </Form.Group>
        {loading ? (
          <Spinner animation="border" role="status" className="d-block mx-auto mt-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Button variant="primary" type="submit" className="mt-3">
            Reset Password
          </Button>
        )}
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        {message && <Alert variant="success" className="mt-3">{message}</Alert>}
      </Form>
    </Container>
  );
}

export default ResetPassword;
