import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Modal, Alert } from 'react-bootstrap';
import fetchWithAuth from '../../../util/fetchWithAuth';
import config from '../../../config';
import ReactPaginate from 'react-paginate'; // Import for pagination

function BankDetails() {
  const [bankDetails, setBankDetails] = useState([]); // Initialize as an empty array
  const [showModal, setShowModal] = useState(false);
  const [newBank, setNewBank] = useState({ bankName: '', accountNo: '' });
  const [editingBankId, setEditingBankId] = useState(null); // To track the bank being edited
  const [editedBank, setEditedBank] = useState({ bankName: '', accountNo: '' }); // Store the edited bank details
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const [itemsPerPage] = useState(20); // Number of items per page
  const [error, setError] = useState(''); // State to hold error messages
  const [showErrorModal, setShowErrorModal] = useState(false); // Control error modal

  useEffect(() => {
    fetchBankDetails();
  }, []);

  const fetchBankDetails = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getBankDetails}`);
      if (response.ok) {
        const jsonData = await response.json();
        if(jsonData.result.success)
        {
          const bankDetailsObject = jsonData.result.data;

          const bankDetailsArray = Object.entries(bankDetailsObject).map(([id, details]) => ({
            id,
            ...details,
          }));

          setBankDetails(bankDetailsArray); // Store the transformed array in state
        }
        else
        {
          throw new Error(jsonData.result.message || 'Error fetching bank details');
        }        
      } 
      else 
      {
        throw new Error('Error fetching bank details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleError = (message) => {
    setError(message);
    setShowErrorModal(true);
  };

  const handleCloseAddBankDialogue = () => {
    setShowModal(false); // Close the modal
    setNewBank({ bankName: '', accountNo: '' }); // Reset the form
  };

  const handleAddBank = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.addBankDetails}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bankName: newBank.bankName,
          accountNo: newBank.accountNo,
        }),
      });

      if (response.ok) 
      {
        const jsonData = await response.json();
        if (jsonData.result.success) 
        {
          fetchBankDetails(); // Refresh list after adding
          setShowModal(false); // Close the modal after adding
          setNewBank({ bankName: '', accountNo: '' }); // Reset the form
        } 
        else 
        {
          handleError((jsonData.result.message || 'Error adding bank details'));
        }
      }
      else
      {
        throw new Error('Error adding bank details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleUpdateBank = (bank) => {
    setEditingBankId(bank.id);
    setEditedBank({ bankName: bank.bankName, accountNo: bank.accountNo });
  };

  const handleSaveUpdate = async (id) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updateBankDetails}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          bankName: editedBank.bankName,
          accountNo: editedBank.accountNo,
        }),
      });

      if (response.ok) 
      {
        const jsonData = await response.json();
        if (jsonData.result.success)
        {
          fetchBankDetails(); // Refresh list after saving
          setEditingBankId(null); // Exit editing mode
        }
        else
        {
          handleError((jsonData.result.message || 'Error updating bank details'));
        }
      } 
      else 
      {
        throw new Error('Error updating bank details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleCancelEdit = () => {
    setEditingBankId(null); // Cancel the editing process
  };

  const handleRemoveBank = async (id, bankName, accountNo) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.deleteBankDetails}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, bankName, accountNo }),
      });

      if (response.ok) 
      {
        const jsonData = await response.json();
        if (jsonData.result.success)
        {
          fetchBankDetails(); // Refresh list after deletion
        }
        else
        {
          handleError((jsonData.result.message || 'Error deleting bank details'));
        }
      }
      else
      {
        throw new Error('Error deleting bank details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const paginatedBankDetails = bankDetails.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div>
      <Button
        onClick={() => setShowModal(true)}
        variant="primary"
        style={{ marginTop: '20px', marginBottom: '20px' }} // Spacing for the button
      >
        Add Bank Details
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Bank Name</th>
            <th>Account Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedBankDetails.length > 0 ? (
            paginatedBankDetails.map((bank) => (
              <tr key={bank.id}>
                {editingBankId === bank.id ? (
                  <>
                    <td>
                      <Form.Control
                        type="text"
                        value={editedBank.bankName}
                        onChange={(e) => setEditedBank({ ...editedBank, bankName: e.target.value })}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={editedBank.accountNo}
                        onChange={(e) => setEditedBank({ ...editedBank, accountNo: e.target.value })}
                      />
                    </td>
                    <td>
                      <Button variant="success" onClick={() => handleSaveUpdate(bank.id)}>
                        Save
                      </Button>
                      <Button variant="secondary" onClick={handleCancelEdit} style={{ marginLeft: '10px' }}>
                        Cancel
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{bank.bankName}</td>
                    <td>{bank.accountNo}</td>
                    <td>
                      <Button
                        variant="warning" // Orange color for update
                        style={{ marginRight: '10px' }} // Spacing between buttons
                        onClick={() => handleUpdateBank(bank)}
                      >
                        Update
                      </Button>
                      <Button variant="danger" onClick={() => handleRemoveBank(bank.id, bank.bankName, bank.accountNo)}>
                        Remove
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center">
                No bank details available
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination */}
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={Math.ceil(bankDetails.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center mt-3'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        disabledClassName={'disabled'}
      />

      {/* Modal for Adding Bank */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="bankName">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter bank name"
                value={newBank.bankName}
                onChange={(e) => setNewBank({ ...newBank, bankName: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="accountNo">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter account number"
                value={newBank.accountNo}
                onChange={(e) => setNewBank({ ...newBank, accountNo: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddBankDialogue}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddBank}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">{error}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BankDetails;
