import React, { useState } from 'react';
import Performance from './performancemanager/Performance';
import Processes from './performancemanager/Processes';
import { Container, Row, Col, ButtonGroup, Button, FormCheck, Tab, Tabs } from 'react-bootstrap';

function PerformanceManager() {
  const [liveUpdate, setLiveUpdate] = useState(true);
  const [selectedRange, setSelectedRange] = useState('1m'); // Default is 1 minute
  const [key, setKey] = useState('performance'); // Default tab

  // Time range options for the performance chart
  const timeRanges = [
    { label: '1 Minute', value: '1m' },
    { label: '15 Minutes', value: '15m' },
    { label: '30 Minutes', value: '30m' },
    { label: '1 Hour', value: '1h' },
    { label: '1 Day', value: '1d' },
    { label: '1 Week', value: '1w' },
    { label: '1 Month', value: '1mo' },
    { label: '3 Months', value: '3mo' }
  ];

  // Handle time range selection
  const handleRangeSelect = (range) => {
    setSelectedRange(range);
    setLiveUpdate(false); // Disable live updates when selecting historical data
  };

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col md={12}>
          <h2>Task Manager - Monitoring</h2>
        </Col>
      </Row>
      
      <Tabs id="monitoring-tabs" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
        <Tab eventKey="performance" title="Performance">
          <Row>
            <Col md={12}>
              {/* Toggle Live Update */}
              <FormCheck
                type="switch"
                label="Live Update"
                checked={liveUpdate}
                onChange={() => setLiveUpdate(!liveUpdate)}
                className="mb-3"
              />
            </Col>
          </Row>

          <Row>
            {/* Time Range Selector */}
            <Col md={12} className="mb-3">
              <ButtonGroup>
                {timeRanges.map((range) => (
                  <Button
                    key={range.value}
                    variant={range.value === selectedRange ? 'primary' : 'outline-primary'}
                    onClick={() => handleRangeSelect(range.value)}
                  >
                    {range.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>

          <Row>
            {/* Performance Chart */}
            <Col md={12}>
              <Performance liveUpdate={liveUpdate} selectedRange={selectedRange} />
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="processes" title="Processes">
          <Row>
            {/* Processes Table */}
            <Col md={12}>
              <Processes />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default PerformanceManager;
