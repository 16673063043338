import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import CustomNavLink from './CustomNavLink';

function NavBar() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <div className="container">
        <Navbar.Brand href="/">MythicGames Portal</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <CustomNavLink to="/download">
              Download
            </CustomNavLink>
            <Nav.Link href="https://discord.gg/fSuYgPxG" target="_blank" rel="noopener noreferrer">
              Discord
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default NavBar;
