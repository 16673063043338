import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Alert, Table, Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import config from '../../config';
import fetchWithAuth from '../../util/fetchWithAuth';
import { format } from 'date-fns';
import './css/PurchaseCreditsRequest.css';

function PurchaseCreditsHistory() {
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [actionType, setActionType] = useState('');
  const [actionReason, setActionReason] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('PAID');
  const [uniqueCodeFilter, setUniqueCodeFilter] = useState('');
  const [sortBy, setSortBy] = useState('createdAt'); // New state for sorting column
  const [sortOrder, setSortOrder] = useState('asc'); // New state for sorting order

  useEffect(() => {
    fetchPurchaseHistory();
  }, []);

  const fetchPurchaseHistory = async () => {
    setActionReason('');
    setLoading(true);
    setError('');
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getPurchaseCreditsRequest}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const jsonData = await response.json();
        setPurchaseHistory(jsonData.result.purchaseHistory || []);
      } else {
        setError('Failed to fetch purchase history');
      }
    } catch (error) {
      setError('Service is temporarily unavailable. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleAction = (purchase, type) => {
    setSelectedPurchase(purchase);
    setActionType(type);
    setShowModal(true);
  };

  const handleConfirmAction = async () => {
    if (!selectedPurchase) return;

    const newPaymentStatus = actionType === 'CANCEL' ? 'CANCELED'
      : actionType === 'REFUND' ? 'REFUNDED'
      : actionType === 'COMPLETE' ? 'COMPLETED'
      : actionType === 'REJECT' ? 'REJECTED'
      : selectedPurchase.paymentStatus;

    const payload = {
      uniqueCode: selectedPurchase.uniqueCode,
      newPaymentStatus,
      credits: selectedPurchase.credits,
      reason: actionReason,
      targetAccountId: selectedPurchase.accountId,
    };

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updatePurchaseCreditsRequest}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        fetchPurchaseHistory();
        setShowModal(false);
      } else {
        setError('Failed to update purchase status');
      }
    } catch (error) {
      setError('Service is temporarily unavailable. Please try again later.');
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(0);
  };

  const handlePaymentStatusFilterChange = (e) => {
    setPaymentStatusFilter(e.target.value);
    setCurrentPage(0);
  };

  const handleUniqueCodeFilterChange = (e) => {
    setUniqueCodeFilter(e.target.value);
    setCurrentPage(0);
  };

  // Sorting function
  const sortData = (data, column, order) => {
    return data.sort((a, b) => {
      const aDate = new Date(a[column]);
      const bDate = new Date(b[column]);
      return order === 'asc' ? aDate - bDate : bDate - aDate;
    });
  };

  const filterAndSortData = () => {
    let filteredData = purchaseHistory || [];

    if (paymentStatusFilter !== 'all') {
      filteredData = filteredData.filter(purchase => purchase.paymentStatus === paymentStatusFilter);
    }

    if (uniqueCodeFilter) {
      filteredData = filteredData.filter(purchase =>
        purchase.uniqueCode && purchase.uniqueCode.toLowerCase().includes(uniqueCodeFilter.toLowerCase())
      );
    }

    // Apply sorting based on the selected column and order
    return sortData(filteredData, sortBy, sortOrder);
  };

  const handleSort = (column) => {
    // If the user clicks the same column, toggle the sort order
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const paginatedData = filterAndSortData().slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const renderPagination = (pageCount) => {
    if (pageCount <= 1) return null;
    return (
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center mt-3'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        disabledClassName={''}
      />
    );
  };

  const renderTable = () => (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Account ID</th>
          <th>Payment Gateway</th>
          <th>Price</th>
          <th>Credits</th>
          <th>Payment Status</th>
          <th>Unique Code</th>
          <th>Reason</th>
          <th>Updated By</th>
          <th style={{ cursor: 'pointer' }} onClick={() => handleSort('createdAt')}>
            Created At {sortBy === 'createdAt' && (sortOrder === 'asc' ? '▲' : '▼')}
          </th>
          <th style={{ cursor: 'pointer' }} onClick={() => handleSort('updatedAt')}>
            Updated At {sortBy === 'updatedAt' && (sortOrder === 'asc' ? '▲' : '▼')}
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {paginatedData.map((purchase, index) => (
          <tr key={purchase.id}>
            <td>{index + 1 + currentPage * itemsPerPage}</td>
            <td>{purchase.accountId}</td>
            <td>{purchase.paymentGateway || 'N/A'}</td>
            <td>{purchase.price.toFixed(2)} SGD</td>
            <td>{purchase.credits.toLocaleString()} credits</td>
            <td>{purchase.paymentStatus}</td>
            <td><span className="highlight-unique-code">{purchase.uniqueCode || 'N/A'}</span></td>
            <td>{purchase.reason || 'N/A'}</td>
            <td>{purchase.updatedBy || 'N/A'}</td>
            <td>{format(new Date(purchase.createdAt), 'dd MMMM yyyy, HH:mm a')}</td>
            <td>{format(new Date(purchase.updatedAt), 'dd MMMM yyyy, HH:mm a')}</td>
            <td>
              {purchase.paymentStatus === 'PENDING' && (
                <Button variant="danger" onClick={() => handleAction(purchase, 'CANCEL')}>
                  Cancel
                </Button>
              )}
              {purchase.paymentStatus === 'COMPLETED' && (
                <Button variant="warning" onClick={() => handleAction(purchase, 'REFUND')}>
                  Refund
                </Button>
              )}
              {purchase.paymentStatus === 'PAID' && (
                <>
                  <Button variant="success" onClick={() => handleAction(purchase, 'COMPLETE')}>
                    Complete
                  </Button>
                  <Button variant="danger" onClick={() => handleAction(purchase, 'REJECT')}>
                    Reject
                  </Button>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const getModalButtonVariant = () => {
    switch (actionType) {
      case 'CANCEL':
        return 'danger';
      case 'REFUND':
        return 'warning';
      case 'COMPLETE':
        return 'success';
      case 'REJECT':
        return 'danger';
      default:
        return 'primary';
    }
  };

  const getModalButtonText = () => {
    return `Confirm ${actionType.charAt(0).toUpperCase() + actionType.slice(1).toLowerCase()} Action`;
  };

  return (
    <Container className="mt-5">
      <h1>Purchase Credits Request</h1>
      {loading ? (
        <Spinner animation="border" role="status" className="d-block mx-auto">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : (
        <>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Filter by Unique Code"
              value={uniqueCodeFilter}
              onChange={handleUniqueCodeFilterChange}
            />
          </InputGroup>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Filter by Payment Status:</Form.Label>
            <Col sm="10">
              <Form.Control as="select" value={paymentStatusFilter} onChange={handlePaymentStatusFilterChange}>
                <option value="all">All</option>
                <option value="PENDING">Pending</option>
                <option value="PAID">Paid</option>
                <option value="COMPLETED">Completed</option>
                <option value="REJECTED">Rejected</option>
                <option value="REFUNDED">Refunded</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Items per page:</Form.Label>
            <Col sm="10">
              <Form.Control as="select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                <option value={20}>20</option>
                <option value={40}>40</option>
                <option value={60}>60</option>
              </Form.Control>
            </Col>
          </Form.Group>
          {renderTable()}
          {renderPagination(Math.ceil(filterAndSortData().length / itemsPerPage))}
        </>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Reason</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={actionReason}
              onChange={(e) => setActionReason(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant={getModalButtonVariant()} onClick={handleConfirmAction}>
            {getModalButtonText()}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default PurchaseCreditsHistory;
