import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const CustomNavLink = ({ to, children, ...props }) => {
  return (
    <Nav.Link as={NavLink} to={to} {...props}>
      {children}
    </Nav.Link>
  );
};

export default CustomNavLink;
