// export default Performance;
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import fetchWithAuth from '../../../util/fetchWithAuth';
import config from '../../../config';

function Performance({ liveUpdate, selectedRange }) {
  const chartRef = useRef(null);
  const cpuChartRef = useRef(null);
  const diskChartRef = useRef(null);


  // State to hold the latest memory details
  const [memoryDetails, setMemoryDetails] = useState({ availableMemory: 0, totalMemory: 0, });
  const [diskDetails, setDiskDetails] = useState([]);

  let tooltip;

  // Fetch performance data
  const fetchData = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.performanceMetrics}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ range: selectedRange }),
      });
      const data = await response.json();
      return data.result.data;
    } catch (error) {
      console.error('Error fetching performance data:', error);
      return [];
    }
  };

  const formatData = (data) => {
    const availableMemoryData = data.filter(d => d.available_memory !== undefined);

    return availableMemoryData.map(d => {
      const formattedTime = new Date(d.time);

      const cpuUsagePerLogicalCore = d.cpu_usage !== undefined && d.cpu_logical_cores ? (d.cpu_usage / d.cpu_logical_cores) * 100 : null;
      const cpuUsagePerPhysicalCore = d.cpu_usage !== undefined && d.cpu_physical_cores ? (d.cpu_usage / d.cpu_physical_cores) * 100 : null;

      const memoryPercentage = d.total_memory ? (d.available_memory / d.total_memory) * 100 : null;

      let diskUsagePercentages = {};
      let diskFields = {};

      Object.keys(d).forEach(key => {
        if (key.startsWith('disk_') && key.endsWith('_total_space')) {
          const diskIndex = key.split('_')[1];
          const totalSpace = d[`disk_${diskIndex}_total_space`];
          const freeSpace = d[`disk_${diskIndex}_free_space`];

          if (totalSpace && freeSpace) {
            const usagePercentage = ((totalSpace - freeSpace) / totalSpace) * 100;
            diskUsagePercentages[`disk_${diskIndex}_usage_percentage`] = usagePercentage;
          }

          diskFields[`disk_${diskIndex}_free_space`] = d[`disk_${diskIndex}_free_space`];
          diskFields[`disk_${diskIndex}_total_space`] = totalSpace;
          diskFields[`disk_${diskIndex}_usable_space`] = d[`disk_${diskIndex}_usable_space`];
        }
      });

      return {
        memoryUsagePercentage: 100 - memoryPercentage,
        available_memory: d.available_memory,        
        total_memory: d.total_memory,

        ...diskFields,
        ...diskUsagePercentages,

        cpuUsagePerLogicalCore: cpuUsagePerLogicalCore,
        cpuUsagePerPhysicalCore: cpuUsagePerPhysicalCore,
        cpu_usage: d.cpu_usage,
        cpu_logical_cores: d.cpu_logical_cores,
        cpu_physical_cores: d.cpu_physical_cores,
        system_total_processes: d.system_total_processes,
        system_total_threads: d.system_total_threads,
        
        system_uptime_seconds: d.system_uptime_seconds,
        time: formattedTime,
      };
    });
  };

  // Helper to format bytes into KB, MB, GB, etc.
  const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  let margin = { top: 20, right: 30, bottom: 30, left: 50 };
  let width = window.innerWidth - margin.left - margin.right - 400;
  let height = 400 - margin.top - margin.bottom;

  const renderChart = async () => {
    const data = await fetchData();
    const formattedData = formatData(data);
    formattedData.sort((a, b) => new Date(a.time) - new Date(b.time));

    // Get the latest data for the memory and CPU display
    const latestData = formattedData[formattedData.length - 1];

    // Set the memory and CPU details state for display
    if (latestData) 
    {
        //Set Memory details
        setMemoryDetails({
            availableMemory: latestData.available_memory,
            totalMemory: latestData.total_memory,
        });

        //Set disk details
        const diskFields = Object.keys(formattedData[0]).filter(key => key.startsWith('disk_') && key.endsWith('_total_space'));
        
        const details = diskFields.map((diskField) => {
            const diskIndex = diskField.split('_')[1]; // Extract disk index from field name
            const totalSpaceField = `disk_${diskIndex}_total_space`;
            const freeSpaceField = `disk_${diskIndex}_free_space`;
        
            // Get the latest data for the specific disk
            const latestData = formattedData[formattedData.length - 1]; // Assuming you want the latest values
        
            return {
            diskIndex,
            totalSpace: latestData[totalSpaceField],
            freeSpace: latestData[freeSpaceField],
            };
        });
        
        setDiskDetails(details); // Update the state with the latest disk details
    }

    renderMemoryChart(formattedData);
    renderCpuChart(formattedData);
    renderDiskChart(formattedData);
  };

  const renderMemoryChart = async (formattedData) => {
    // Remove existing SVGs for memory chart
    d3.select(chartRef.current).selectAll('*').remove();

    // Define scales and append svg for memory usage
    const svgMemory = d3.select(chartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleTime()
      .domain(d3.extent(formattedData, d => new Date(d.time)))
      .range([0, width]);

    const yMemory = d3.scaleLinear()
      .domain([0, 100])  // Memory Usage Percentage is between 0 and 100
      .range([height, 0]);

    const lineMemory = d3.line()
      .x(d => x(new Date(d.time)))
      .y(d => yMemory(d.memoryUsagePercentage));

    // Add title for memory chart
    svgMemory.append('text')
      .attr('x', width / 2)
      .attr('y', 10)
      .attr('text-anchor', 'middle')
      .style('font-size', '16px')
      .text('Memory Usage Percentage');

    svgMemory.append('path')
      .datum(formattedData)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 1.5)
      .attr('d', lineMemory);

    svgMemory.append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%H:%M:%S')));

    svgMemory.append('g')
      .call(d3.axisLeft(yMemory));

    // Tooltip for Memory chart
    svgMemory.selectAll('circle')
      .data(formattedData)
      .enter()
      .append('circle')
      .attr('r', 3)
      .attr('cx', d => x(new Date(d.time)))
      .attr('cy', d => yMemory(d.memoryUsagePercentage))
      .attr('fill', 'steelblue')
      .on('mouseover', (event, d) => {
        tooltip.transition()
          .duration(200)
          .style('opacity', 0.9);
        tooltip.html(`Time: ${d3.timeFormat('%H:%M:%S')(d.time)}<br>Memory: ${d.memoryUsagePercentage.toFixed(2)}%`)
          .style('left', `${event.pageX + 5}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', () => {
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });
  };

  const renderCpuChart = async (formattedData) => {
    // Remove existing SVGs for CPU chart
    d3.select(cpuChartRef.current).selectAll('*').remove();

    const x = d3.scaleTime()
    .domain(d3.extent(formattedData, d => new Date(d.time)))
    .range([0, width]);

    // Define scales and append svg for CPU usage chart
    const svgCPU = d3.select(cpuChartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const yCPU = d3.scaleLinear()
      .domain([0, 100])  // CPU Usage Percentage is between 0 and 100
      .range([height, 0]);

    const lineCPULogical = d3.line()
      .x(d => x(new Date(d.time)))
      .y(d => yCPU(d.cpuUsagePerLogicalCore));

    const lineCPUPhysical = d3.line()
      .x(d => x(new Date(d.time)))
      .y(d => yCPU(d.cpuUsagePerPhysicalCore));

    // Add title for CPU chart
    svgCPU.append('text')
      .attr('x', width / 2)
      .attr('y', 10)
      .attr('text-anchor', 'middle')
      .style('font-size', '16px')
      .text('CPU Usage (Logical vs Physical Cores)');

    // Add logical cores line (blue)
    svgCPU.append('path')
      .datum(formattedData)
      .attr('fill', 'none')
      .attr('stroke', 'blue')
      .attr('stroke-width', 1.5)
      .attr('d', lineCPULogical);

    // Add physical cores line (red)
    // Add physical cores line (red)
    svgCPU.append('path')
      .datum(formattedData)
      .attr('fill', 'none')
      .attr('stroke', 'red')
      .attr('stroke-width', 1.5)
      .attr('d', lineCPUPhysical);

    // Add x and y axes for CPU chart
    svgCPU.append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%H:%M:%S')));

    svgCPU.append('g')
      .call(d3.axisLeft(yCPU));

    // Tooltip for CPU chart
    svgCPU.selectAll('circle.logical')
      .data(formattedData)
      .enter()
      .append('circle')
      .attr('class', 'logical')
      .attr('r', 3)
      .attr('cx', d => x(new Date(d.time)))
      .attr('cy', d => yCPU(d.cpuUsagePerLogicalCore))
      .attr('fill', 'blue')
      .on('mouseover', (event, d) => {
        tooltip.transition()
          .duration(200)
          .style('opacity', 0.9);
        tooltip.html(`Time: ${d3.timeFormat('%H:%M:%S')(d.time)}<br>Logical Core Usage: ${d.cpuUsagePerLogicalCore.toFixed(2)}%`)
          .style('left', `${event.pageX + 5}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', () => {
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });

    svgCPU.selectAll('circle.physical')
      .data(formattedData)
      .enter()
      .append('circle')
      .attr('class', 'physical')
      .attr('r', 3)
      .attr('cx', d => x(new Date(d.time)))
      .attr('cy', d => yCPU(d.cpuUsagePerPhysicalCore))
      .attr('fill', 'red')
      .on('mouseover', (event, d) => {
        tooltip.transition()
          .duration(200)
          .style('opacity', 0.9);
        tooltip.html(`Time: ${d3.timeFormat('%H:%M:%S')(d.time)}<br>Physical Core Usage: ${d.cpuUsagePerPhysicalCore.toFixed(2)}%`)
          .style('left', `${event.pageX + 5}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', () => {
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });

    // Add legend to the CPU chart
    svgCPU.append('circle').attr('cx', width - 100).attr('cy', 10).attr('r', 6).style('fill', 'blue');
    svgCPU.append('text').attr('x', width - 85).attr('y', 15).text('Logical Core').style('font-size', '12px').attr('alignment-baseline', 'middle');

    svgCPU.append('circle').attr('cx', width - 100).attr('cy', 30).attr('r', 6).style('fill', 'red');
    svgCPU.append('text').attr('x', width - 85).attr('y', 35).text('Physical Core').style('font-size', '12px').attr('alignment-baseline', 'middle');
  }

  const renderDiskChart = async (formattedData) => {
    // Remove existing SVGs for the disk chart
    d3.select(diskChartRef.current).selectAll('*').remove();
  
    const svgDisk = d3.select(diskChartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
  
    const x = d3.scaleTime()
      .domain(d3.extent(formattedData, d => new Date(d.time)))
      .range([0, width]);
  
    // Dynamically find all disk usage fields (disk_0, disk_1, etc.)
    const diskFields = Object.keys(formattedData[0]).filter(key => key.startsWith('disk_') && key.endsWith('_usage_percentage'));
  
    // Create separate color for each disk
    const color = d3.scaleOrdinal(d3.schemeCategory10).domain(diskFields);
  
    const yDisk = d3.scaleLinear()
      .domain([0, 100])  // Disk Usage Percentage is between 0 and 100
      .range([height, 0]);
  
    // Add title for the disk chart
    svgDisk.append('text')
      .attr('x', width / 2)
      .attr('y', 10)
      .attr('text-anchor', 'middle')
      .style('font-size', '16px')
      .text('Disk Usage Percentage');
  
    // Loop over each disk and draw its line
    diskFields.forEach((diskField, index) => {
      const lineDisk = d3.line()
        .x(d => x(new Date(d.time)))
        .y(d => yDisk(d[diskField]));
  
      // Add line for each disk
      svgDisk.append('path')
        .datum(formattedData)
        .attr('fill', 'none')
        .attr('stroke', color(diskField))  // Use dynamic color for each disk
        .attr('stroke-width', 1.5)
        .attr('d', lineDisk);
  
      // Bind diskField value to data and append circles for each disk
      svgDisk.selectAll(`.disk-${index}`)
        .data(formattedData)
        .enter()
        .append('circle')
        .attr('r', 3)
        .attr('cx', d => x(new Date(d.time)))
        .attr('cy', d => yDisk(d[diskField]))
        .attr('fill', color(diskField))
        .on('mouseover', (event, d) => {
          tooltip.transition()
            .duration(200)
            .style('opacity', 0.9);
          tooltip.html(`Time: ${d3.timeFormat('%H:%M:%S')(d.time)}<br>${diskField}: ${d[diskField].toFixed(2)}%`)
            .style('left', `${event.pageX + 5}px`)
            .style('top', `${event.pageY - 28}px`);
        })
        .on('mouseout', () => {
          tooltip.transition()
            .duration(500)
            .style('opacity', 0);
        });
    });
  
    // Add x and y axes for the disk chart
    svgDisk.append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%H:%M:%S')));
  
    svgDisk.append('g')
      .call(d3.axisLeft(yDisk));
  
    // Add legend for the disk chart
    diskFields.forEach((diskField, index) => {
      svgDisk.append('circle')
        .attr('cx', width - 100)
        .attr('cy', 10 + index * 20)
        .attr('r', 6)
        .style('fill', color(diskField));
  
      svgDisk.append('text')
        .attr('x', width - 85)
        .attr('y', 15 + index * 20)
        .text(diskField)
        .style('font-size', '12px')
        .attr('alignment-baseline', 'middle');
    });
  };

  //Main App

  useEffect(() => {
    tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('background-color', '#333')
      .style('color', '#fff')
      .style('padding', '5px')
      .style('border-radius', '5px')
      .style('pointer-events', 'none');

    renderChart();
    if (liveUpdate) {
      const interval = setInterval(renderChart, 5000);
      return () => clearInterval(interval);
    }
  }, [liveUpdate, selectedRange]);

  return (
    <div>
        <div style={{ marginTop: '20px', fontSize: '16px' }}>
            <strong style={{ color: 'green' }}>Last Updated:</strong> {new Date().toLocaleString()} <br />
        </div>
        <div ref={chartRef}></div>
        <div style={{ marginTop: '20px', fontSize: '16px' }}>
            <strong>Memory Usage:</strong> {formatBytes(memoryDetails.totalMemory - memoryDetails.availableMemory)} <br />
            <strong>Available Memory:</strong> {formatBytes(memoryDetails.availableMemory)} <br />
            <strong>Total Memory:</strong> {formatBytes(memoryDetails.totalMemory)} <br />
        </div>
        <hr />
        <div ref={cpuChartRef}></div>
        <hr />
        <div ref={diskChartRef}></div>
        <div style={{ marginTop: '20px', fontSize: '16px' }}>
            {diskDetails.map(disk => (
                <div key={disk.diskIndex}>
                <strong>Disk {disk.diskIndex}:</strong> <br />
                Free Space: {formatBytes(disk.freeSpace)} <br />
                Total Space: {formatBytes(disk.totalSpace)} <br />
                </div>
            ))}
        </div>
    </div>
  );
}

export default Performance;
