// export default VoteDetails;

import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Modal, Alert } from 'react-bootstrap';
import fetchWithAuth from '../../../util/fetchWithAuth';
import config from '../../../config';
import ReactPaginate from 'react-paginate'; // Import for pagination

function VoteDetails() {
  const [voteDetails, setVoteDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newVote, setNewVote] = useState({ name: '', voteSite: '' });
  const [editingVoteId, setEditingVoteId] = useState(null); // To track the vote site being edited
  const [editedVote, setEditedVote] = useState({ name: '', voteSite: '' });
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const [itemsPerPage] = useState(20); // Number of items per page
  const [error, setError] = useState(''); // State to hold error messages
  const [showErrorModal, setShowErrorModal] = useState(false); // Control error modal

  useEffect(() => {
    fetchVoteDetails();
  }, []);

  const fetchVoteDetails = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.voteDetails}`);
      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          const voteData = jsonData.result.data;
          const voteArray = Object.entries(voteData).map(([id, details]) => ({
            id,
            ...details,
          }));
          setVoteDetails(voteArray); // Store the transformed array in state
        } else {
          handleError(jsonData.result.message || 'Error fetching vote details');
        }
      } else {
        throw new Error('Error fetching vote details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleError = (message) => {
    setError(message);
    setShowErrorModal(true);
  };

  const handleAddVote = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.addVoteDetails}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: newVote.name,
          voteSite: newVote.voteSite,
        }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          fetchVoteDetails(); // Refresh list after adding
          setShowModal(false); // Close the modal after adding
          setNewVote({ name: '', voteSite: '' }); // Reset the form
        } else {
          handleError(jsonData.result.message || 'Error adding vote details');
        }
      } else {
        throw new Error('Error adding vote details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleUpdateVote = (vote) => {
    setEditingVoteId(vote.id);
    setEditedVote({ name: vote.name, voteSite: vote.voteSite });
  };

  const handleSaveUpdate = async (id) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updateVoteDetails}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          name: editedVote.name,
          voteSite: editedVote.voteSite,
        }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          fetchVoteDetails(); // Refresh list after saving
          setEditingVoteId(null); // Exit editing mode
        } else {
          handleError(jsonData.result.message || 'Error updating vote details');
        }
      } else {
        throw new Error('Error updating vote details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleRemoveVote = async (id, name, voteSite) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.deleteVoteDetails}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          id,
          name: name,
          voteSite: voteSite, 
        }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          fetchVoteDetails(); // Refresh list after deletion
        } else {
          handleError(jsonData.result.message || 'Error deleting vote details');
        }
      } else {
        throw new Error('Error deleting vote details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const toggleIsActive = async (id, isActive) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updateVoteActiveDetails}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          isActive: !isActive, // Toggle active state
        }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          fetchVoteDetails(); // Refresh the list after updating isActive
        } else {
          handleError(jsonData.result.message || 'Error updating vote status');
        }
      } else {
        throw new Error('Error updating vote status');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const paginatedVoteDetails = voteDetails.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div>
      <Button
        onClick={() => setShowModal(true)}
        variant="primary"
        style={{ marginTop: '20px', marginBottom: '20px' }} // Spacing for the button
      >
        Add Vote Details
      </Button>

      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th style={{ width: '30%' }}>Vote Site</th> {/* Adjust the width for the Vote Site column */}
          <th>Status</th>
          <th style={{ width: '40%' }}>Actions</th> {/* Increase the width for the Actions column */}
        </tr>
      </thead>
      <tbody>
        {paginatedVoteDetails.length > 0 ? (
          paginatedVoteDetails.map((vote) => (
            <tr key={vote.id}>
              {editingVoteId === vote.id ? (
                <>
                  <td>
                    <Form.Control
                      type="text"
                      value={editedVote.name}
                      onChange={(e) => setEditedVote({ ...editedVote, name: e.target.value })}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={editedVote.voteSite}
                      onChange={(e) => setEditedVote({ ...editedVote, voteSite: e.target.value })}
                    />
                  </td>
                  <td>{vote.isActive ? 'Active' : 'Inactive'}</td>
                  <td>
                    <Button variant="success" onClick={() => handleSaveUpdate(vote.id)}>
                      Save
                    </Button>
                    <Button variant="secondary" onClick={() => setEditingVoteId(null)} style={{ marginLeft: '10px' }}>
                      Cancel
                    </Button>
                  </td>
                </>
              ) : (
                <>
                  <td>{vote.name}</td>
                  <td>{vote.voteSite}</td>
                  <td>{vote.isActive ? 'Active' : 'Inactive'}</td>
                  <td>
                    <Button
                      variant="warning"
                      style={{ marginRight: '10px' }}
                      onClick={() => handleUpdateVote(vote)}
                    >
                      Update
                    </Button>
                    <Button variant="danger" onClick={() => handleRemoveVote(vote.id, vote.name, vote.voteSite)}>
                      Remove
                    </Button>
                    <Button
                      variant={vote.isActive ? 'danger' : 'success'}
                      onClick={() => toggleIsActive(vote.id, vote.isActive)}
                      style={{ marginLeft: '10px' }}
                    >
                      {vote.isActive ? 'Disable' : 'Enable'}
                    </Button>
                  </td>
                </>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" className="text-center">
              No vote details available
            </td>
          </tr>
        )}
      </tbody>
    </Table>


      {/* Pagination */}
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={Math.ceil(voteDetails.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center mt-3'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        disabledClassName={'disabled'}
      />

      {/* Modal for Adding Vote Details */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Vote Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newVote.name}
                onChange={(e) => setNewVote({ ...newVote, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="voteSite">
              <Form.Label>Vote Site</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter vote site"
                value={newVote.voteSite}
                onChange={(e) => setNewVote({ ...newVote, voteSite: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddVote}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">{error}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default VoteDetails;
