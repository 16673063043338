import React, { useState } from 'react';
import { Tab, Tabs, Container } from 'react-bootstrap';
import OneTimePasswordDetails from './webservercache/OneTimePasswordDetails';
import LoginAttemptDetails from './webservercache/LoginAttemptDetails';

function WebServerCache() {
  const [key, setKey] = useState('otp');

  return (
    <div className="web-server-cache">
      <h1>Web Server Cache</h1>
      <Tabs
        id="web-server-cache-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="otp" title="One-Time Passcode">
          <OneTimePasswordDetails />
        </Tab>
        <Tab eventKey="login" title="Login Attempt">
          <LoginAttemptDetails />
        </Tab>
      </Tabs>
    </div>
  );
}

export default WebServerCache;
