import React, { useState, useEffect } from 'react';
import { Table, Button, Alert, Modal, Spinner } from 'react-bootstrap';
import fetchWithAuth from '../../../util/fetchWithAuth';
import config from '../../../config';
import ReactPaginate from 'react-paginate'; // Import for pagination

function OneTimePasswordDetails() {
  const [otpCache, setOtpCache] = useState([]); // OTP data
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const [itemsPerPage] = useState(10); // Number of items per page
  const [loading, setLoading] = useState(true); // Loading spinner
  const [error, setError] = useState(''); // Error state
  const [showErrorModal, setShowErrorModal] = useState(false); // Error modal control
  const [showSuccessModal, setShowSuccessModal] = useState(false); // Success modal control
  const [successMessage, setSuccessMessage] = useState(''); // Success message

  useEffect(() => {
    fetchOtpCache();
  }, []);

  const fetchOtpCache = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getOtpCache}`);
      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) {
        const otpData = jsonData.result.otpCache ? Object.entries(jsonData.result.otpCache) : [];
        setOtpCache(otpData);
      } else {
        throw new Error(jsonData.result.message || 'Error fetching OTP cache');
      }
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (message) => {
    setError(message);
    setShowErrorModal(true);
  };

  const handleRemoveOtp = async (username) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.removeOtp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: username }),
      });

      const jsonData = await response.json();
      if (response.ok && jsonData.result.success) {
        fetchOtpCache(); // Refresh OTP cache
      } else {
        handleError(jsonData.result.message || 'Error removing OTP');
      }
    } catch (err) {
      handleError('Network error: Unable to remove OTP');
    }
  };

  const handleResendOtp = async (username) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.adminResendOtp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: username }),
      });

      const jsonData = await response.json();
      if (response.ok && jsonData.result.success) {
        setSuccessMessage(jsonData.result.message); // Set success message
        setShowSuccessModal(true); // Show success modal
      } else {
        handleError(jsonData.result.message || 'Error resending OTP');
      }
    } catch (err) {
      handleError('Network error: Unable to resend OTP');
    }
  };

  const handleRegenerateAndResendOtp = async (username) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.regenerateOtp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: username }),
      });

      const jsonData = await response.json();
      if (response.ok && jsonData.result.success) {
        fetchOtpCache(); // Refresh OTP cache
        setSuccessMessage(jsonData.result.message); // Set success message
        setShowSuccessModal(true); // Show success modal
      } else {
        handleError(jsonData.result.message || 'Error regenerating and resending OTP');
      }
    } catch (err) {
      handleError('Network error: Unable to regenerate and resend OTP');
    }
  };

  const handleCleanExpiredOtp = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.cleanExpiredOtp}`, {
        method: 'POST',
      });

      const jsonData = await response.json();
      if (response.ok && jsonData.result.success) {
        fetchOtpCache(); // Refresh OTP cache
        setSuccessMessage(jsonData.result.message); // Set success message
        setShowSuccessModal(true); // Show success modal
      } else {
        handleError(jsonData.result.message || 'Error cleaning expired OTPs');
      }
    } catch (err) {
      handleError('Network error: Unable to clean expired OTPs');
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const paginatedOtpCache = otpCache.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  return (
    <div>
      <Button
        onClick={handleCleanExpiredOtp}
        variant="danger"
        style={{ marginBottom: '20px' }} // Spacing for the button
      >
        Clean Expired OTPs
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Username</th>
            <th>OTP</th>
            <th>Expiry Time</th>
            <th>Send Count</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5" className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </td>
            </tr>
          ) : paginatedOtpCache.length > 0 ? (
            paginatedOtpCache.map(([username, { otp, expiryTime, sendCount }]) => (
              <tr key={username}>
                <td>{username}</td>
                <td>{otp}</td>
                <td>{new Date(expiryTime).toLocaleString()}</td>
                <td>{sendCount}</td>
                <td>
                  <Button variant="warning" onClick={() => handleRemoveOtp(username)} style={{ marginRight: '10px' }}>
                    Remove OTP
                  </Button>
                  <Button variant="success" onClick={() => handleResendOtp(username)} style={{ marginRight: '10px' }}>
                    Resend Current OTP
                  </Button>
                  <Button variant="primary" onClick={() => handleRegenerateAndResendOtp(username)}>
                    Regenerate and Resend OTP
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No OTP details available
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={Math.ceil(otpCache.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center mt-3'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        disabledClassName={'disabled'}
      />

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">{error}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">{successMessage}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default OneTimePasswordDetails;
