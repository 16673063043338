import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [username, setUser] = useState(null);
  const [roleId, setRole] = useState(null);

  return (
    <UserContext.Provider value={{ username, setUser, roleId, setRole }}>
      {children}
    </UserContext.Provider>
  );
};
