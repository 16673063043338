import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Alert, Table, Form, Nav } from 'react-bootstrap';
import config from '../../config';
import fetchWithAuth from '../../util/fetchWithAuth';
import './css/CreditsHistory.css';
import { format } from 'date-fns';

function CreditsHistory() {
  const [voteHistory, setVoteHistory] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [tradeHistory, setTradeHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [totalItems, setTotalItems] = useState('20');
  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    fetchHistory(totalItems);
  }, [totalItems]);

  const fetchHistory = async (items) => {
    setLoading(true);
    setError('');
    try {
      const url = new URL(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getCreditHistory}`);
      url.searchParams.append('totalItems', items);

      const response = await fetchWithAuth(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          setVoteHistory(jsonData.result.voteHistory);
          setPurchaseHistory(jsonData.result.purchaseHistory);
          setTradeHistory(jsonData.result.creditTradeHistory);
        } else {
          setError(jsonData.result.message || 'Failed to fetch credit history');
        }
      } else {
        setError('Failed to fetch credit history');
      }
    } catch (error) {
      setError('Service is temporarily unavailable. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleItemsChange = (e) => {
    setTotalItems(e.target.value);
  };

  const formatDate = (timestamp) => {
    return format(new Date(timestamp), 'dd MMMM yyyy, HH:mm a');
  };

  const renderVoteHistory = () => (
    voteHistory.length > 0 ? (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Status</th>
            <th>Credits</th>
            <th>Comments</th>
            <th>Voted Date</th>
          </tr>
        </thead>
        <tbody>
          {voteHistory.map((vote, index) => (
            <tr key={vote.id}>
              <td>{index + 1}</td>
              <td>{vote.transactionStatus}</td>
              <td>{vote.creditAmount}</td>
              <td>{vote.reason === '' || vote.reason === null ? "N/A" : vote.reason}</td>
              <td>{formatDate(vote.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p className="text-center mt-3">No historical data</p>
    )
  );

  const renderPurchaseHistory = () => (
    purchaseHistory.length > 0 ? (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Purchased Price</th>
            <th>Credits Purchased</th>
            <th>Payment Status</th>
            <th>Unique Code</th>
            <th>Comments</th>
            <th>Purchase Date</th>
            <th>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {purchaseHistory.map((purchase, index) => (
            <tr key={purchase.id}>
              <td>{index + 1}</td>
              <td>{purchase.price.toFixed(2)} SGD</td>
              <td>{purchase.credits.toLocaleString()} credits</td>
              <td>{purchase.paymentStatus}</td>
              <td>{purchase.uniqueCode}</td>
              <td>{purchase.reason === '' || purchase.reason === null ? "N/A" : purchase.reason}</td>
              <td>{formatDate(purchase.createdAt)}</td>
              <td>{formatDate(purchase.updatedAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p className="text-center mt-3">No historical data</p>
    )
  );

  const renderTradeHistory = () => (
    tradeHistory.length > 0 ? (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Credits Used</th>
            <th>Currency Amount</th>
            <th>Game</th>
            <th>Traded Date</th>
          </tr>
        </thead>
        <tbody>
          {tradeHistory.map((trade, index) => (
            <tr key={trade.id}>
              <td>{index + 1}</td>
              <td>{`-${trade.credits.toLocaleString()} credits`}</td>
              <td>{`${trade.currencyAmount.toLocaleString()} ${trade.currencyType}`}</td>
              <td>{trade.destination}</td>
              <td>{formatDate(trade.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p className="text-center mt-3">No historical data</p>
    )
  );

  const renderAllHistory = () => {
    const allData = [
      ...voteHistory.filter((vote) => vote.transactionStatus === 'APPROVED').map((vote, index) => ({
        id: `vote-${vote.id}`,
        credits: vote.creditAmount,
        debitedDate: vote.createdAt,
        source: 'Vote',
      })),
      ...purchaseHistory.map((purchase, index) => ({
        id: `purchase-${purchase.id}`,
        credits: purchase.credits,
        debitedDate: purchase.updatedAt,
        source: 'Purchase',
      })),
      ...tradeHistory.map((trade, index) => ({
        id: `trade-${trade.id}`,
        credits: `-${trade.credits.toLocaleString()}`,
        debitedDate: trade.createdAt,
        source: trade.destination,
      })),
    ].sort((a, b) => b.debitedDate - a.debitedDate);
  
    return allData.length > 0 ? (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Credits</th>
            <th>Transaction Date</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {allData.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.credits}</td>
              <td>{formatDate(item.debitedDate)}</td>
              <td>{item.source}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p className="text-center mt-3">No historical data</p>
    );
  };  

  return (
    <Container className="mt-5">
      <h1>Credits History</h1>
      {loading ? (
        <Spinner animation="border" role="status" className="d-block mx-auto">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : (
        <>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Show items:</Form.Label>
            <Col sm="10">
              <Form.Control as="select" value={totalItems} onChange={handleItemsChange}>
                <option value="20" disabled={totalItems !== "20"}>20</option>
                <option value="40" disabled={totalItems === "60" && totalItems !== "20"} >40</option>
                <option value="60" >60</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedTab) => setActiveTab(selectedTab)}>
            <Nav.Item>
              <Nav.Link eventKey="all">All</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="voteHistory">Vote History</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="purchaseHistory">Purchase History</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tradeHistory">Trade History</Nav.Link>
            </Nav.Item>
          </Nav>

          {activeTab === 'all' && renderAllHistory()}
          {activeTab === 'voteHistory' && renderVoteHistory()}
          {activeTab === 'purchaseHistory' && renderPurchaseHistory()}
          {activeTab === 'tradeHistory' && renderTradeHistory()}
        </>
      )}
    </Container>
  );
}

export default CreditsHistory;
