import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { UserContext } from '../../context/UserContext';
import config from '../../config';
import { useExecuteRecaptcha } from '../../util/ReCaptcha';

function Login({ setIsLoggedIn }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill('')); // Array to store each OTP digit
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [requestOtp, setRequestOtp] = useState(false);
  const [countdown, setCountdown] = useState(180); // 3 minutes for OTP
  const [otpError, setOtpError] = useState('');
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpResendDisabled, setOtpResendDisabled] = useState(true); // Disable resend initially

  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { setRole } = useContext(UserContext);
  const { execute } = useExecuteRecaptcha();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const token = await execute('login'); // Execute reCAPTCHA

      const response = await fetch(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.login}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: username,
          password: password,
          reCaptchaToken: token,
        }),
      });

      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) {
        if (jsonData.result.requestOtp) {
          setRequestOtp(true); // If OTP is required, switch to OTP view
          setCountdown(180); // Reset OTP countdown
          setOtpResendDisabled(true); // Disable resend button initially
        } else {
          const token = jsonData.result.token;
          const roleId = jsonData.result.roleId;
          if (token) {
            sessionStorage.setItem('jwt', token);
            setIsLoggedIn(true);
            setUser(username);
            setRole(roleId);
            navigate('/');
          }
        }
      } else {
        setError(jsonData.result.message || 'Login failed: Invalid username or password');
      }
    } catch (error) {
      setError('Network error: Unable to log in');
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (element, index) => {
    if (/^[0-9]$/.test(element.value) || element.value === '') { // Only allow digits and handle backspace
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      // Move to the next input
      if (element.nextSibling && element.value !== '') {
        element.nextSibling.focus();
      }
      // Move to the previous input if backspacing
      if (element.previousSibling && element.value === '') {
        element.previousSibling.focus();
      }

      // If all 6 digits are filled, automatically trigger OTP validation
      if (newOtp.every(digit => digit !== '')) {
        handleOtpSubmit(newOtp.join(''));
      }
    }
  };

  const handleOtpSubmit = async (otpCode) => {
    setOtpLoading(true);
    setOtpError('');
    try {
      const token = await execute('submitOtp'); // Execute reCAPTCHA

      const response = await fetch(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.submitOtp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: username,
          otp: otpCode,
          reCaptchaToken: token,
        }),
      });

      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) {
        const token = jsonData.result.token;
        const roleId = jsonData.result.roleId;
        sessionStorage.setItem('jwt', token);
        setIsLoggedIn(true);
        setUser(username);
        setRole(roleId);
        navigate('/');
      } else {
        setOtpError(jsonData.result.message || 'Invalid OTP. Please try again.');
        setOtp(new Array(6).fill('')); // Clear OTP input boxes after invalid attempt
      }
    } catch (error) {
      setOtpError('Network error: Unable to verify OTP');
    } finally {
      setOtpLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const token = await execute('resendOtp'); // Execute reCAPTCHA

      const response = await fetch(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.resendOtp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          name: username,
          reCaptchaToken: token,}),
      });

      const jsonData = await response.json();
      if (jsonData.result.success) {
        setCountdown(180); // Restart OTP countdown
        setOtpResendDisabled(true); // Disable resend button after resending
      } else {
        
        setOtpError(jsonData.result.message || 'Error sending OTP. Please try again later.');
      }
    } catch (error) {
      setOtpError('Network error: Unable to resend OTP');
    } finally {
      setLoading(false);
    }
  };

  // Timer for OTP countdown
  useEffect(() => {
    if (requestOtp && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup timer
    }

    if (countdown === 0) {
      setOtpResendDisabled(false); // Enable resend OTP button
    }
  }, [requestOtp, countdown]);

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={4}>
          {requestOtp ? (
            <>
              <h3>Enter OTP</h3>
              <p>Didn't receive OTP? Please wait for {Math.floor(countdown / 60)}:{countdown % 60} to request for a new OTP.</p>
              <Form>
                <div className="otp-inputs">
                  {otp.map((digit, index) => (
                    <Form.Control
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e.target, index)}
                      style={{ width: '40px', display: 'inline-block', margin: '0 5px', textAlign: 'center' }}
                    />
                  ))}
                </div>
              </Form>
              
              {otpError && <Alert variant="danger" className="mt-3">{otpError}</Alert>}

              <Button
                variant="primary" // Now enabled to blue after 3 minutes
                onClick={handleResendOtp}
                disabled={otpResendDisabled}
                className="mt-3"
              >
                Resend OTP
              </Button>

              {otpLoading && <Spinner animation="border" className="mt-3" />}
            </>
          ) : (
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>

              {loading ? (
                <Spinner animation="border" role="status" className="d-block mx-auto mt-3">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <>
                  <div className="d-flex justify-content-between mt-3">
                    <Button variant="link" onClick={() => navigate('/forget-password')}>
                      Forget password
                    </Button>
                    <div className="d-flex justify-content-end">
                      <Button variant="link" onClick={() => navigate('/register')}>
                        Register account
                      </Button>
                      <Button variant="primary" type="submit" className="ml-2">
                        Next
                      </Button>
                    </div>
                  </div>
                  {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                </>
              )}
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
