import React from 'react';
import { Outlet } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap';

function Home() {
  return (
    <div className="home-container">
      <Content />
      <Outlet /> {/* This will render nested routes */}
    </div>
  );
}

function Content() {
  return (
    <Container fluid className="mt-4">
      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Something Exciting is Coming!</Card.Title>
              <Card.Text>
                We're working on something awesome. Stay tuned for amazing features and updates. Check back soon!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
