import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const useExecuteRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const execute = async (action) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return '';
    }

    try {
      const token = await executeRecaptcha(action);
      return token;
    } catch (error) {
      console.error('Recaptcha execution failed', error);
      return '';
    }
  };

  return { execute };
};
