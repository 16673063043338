import React, { useState, useEffect } from 'react';
import { Table, Button, Alert, Modal, Spinner } from 'react-bootstrap';
import fetchWithAuth from '../../../util/fetchWithAuth';
import config from '../../../config';
import ReactPaginate from 'react-paginate'; // Import for pagination

function LoginAttemptDetails() {
  const [attemptsCache, setAttemptsCache] = useState([]); // Attempt data
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const [itemsPerPage] = useState(10); // Number of items per page
  const [loading, setLoading] = useState(true); // Loading spinner
  const [error, setError] = useState(''); // Error state
  const [showErrorModal, setShowErrorModal] = useState(false); // Error modal control
  const [showSuccessModal, setShowSuccessModal] = useState(false); // Success modal control
  const [successMessage, setSuccessMessage] = useState(''); // Success message

  useEffect(() => {
    fetchAttemptsCache();
  }, []);

  const fetchAttemptsCache = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getLoginAttemptCache}`);
      const jsonData = await response.json();
  
      if (response.ok && jsonData.result.success) {
        const attemptsData = jsonData.result.loginAttemptCache ? Object.entries(jsonData.result.loginAttemptCache) : [];
        setAttemptsCache(attemptsData); // Set the attempt cache data
      } else {
        throw new Error(jsonData.result.message || 'Error fetching login attempts cache');
      }
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (message) => {
    setError(message);
    setShowErrorModal(true);
  };

  const handleClearAttempts = async (username) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.clearAttempts}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          name: username }),
      });

      const jsonData = await response.json();
      if (response.ok && jsonData.result.success) {
        fetchAttemptsCache(); // Refresh attempts cache
      } else {
        handleError(jsonData.result.message || 'Error clearing attempts');
      }
    } catch (err) {
      handleError('Network error: Unable to clear attempts');
    }
  };

  const handleCleanExpiredAttempts = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.cleanExpiredAttempts}`, {
        method: 'POST',
      });

      const jsonData = await response.json();
      if (response.ok && jsonData.result.success) {
        fetchAttemptsCache(); // Refresh attempts cache
        setSuccessMessage('Expired login attempts cleaned successfully');
        setShowSuccessModal(true); // Show success modal
      } else {
        handleError(jsonData.result.message || 'Error cleaning expired attempts');
      }
    } catch (err) {
      handleError('Network error: Unable to clean expired attempts');
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const paginatedAttemptsCache = attemptsCache.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  return (
    <div>
      <Button
        onClick={handleCleanExpiredAttempts}
        variant="danger"
        style={{ marginBottom: '20px' }} // Spacing for the button
      >
        Clean Expired Attempts
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Username</th>
            <th>Attempts</th>
            <th>Last Attempt Time</th>
            <th>Expiry Timing</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5" className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </td>
            </tr>
          ) : paginatedAttemptsCache.length > 0 ? (
            paginatedAttemptsCache.map(([username, { attempts, lastAttemptTime, lastAttemptExpiryTiming }]) => (
              <tr key={username}>
                <td>{username}</td>
                <td>{attempts}</td>
                <td>{new Date(lastAttemptTime).toLocaleString()}</td>
                <td>{new Date(lastAttemptExpiryTiming).toLocaleString()}</td>
                <td>
                  <Button variant="warning" onClick={() => handleClearAttempts(username)}>
                    Clear Attempts
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No login attempts available
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={Math.ceil(attemptsCache.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center mt-3'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        disabledClassName={'disabled'}
      />

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">{error}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">{successMessage}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LoginAttemptDetails;
