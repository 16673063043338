import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import BankDetails from './webinformation/BankDetails';
import PurchaseCreditsDetails from './webinformation/PurchaseCreditsDetails';
import VoteDetails from './webinformation/VoteDetails';
import GameCurrencyDetails from './webinformation/GameCurrencyDetails';
import './css/WebInformation.css';

function WebInformation() {
  const [key, setKey] = useState('bankDetails');

  return (
    <div className="web-information">
      <h1>Web Information</h1>
      <Tabs id="web-info-tabs" activeKey={key} onSelect={(k) => setKey(k)}>
        <Tab eventKey="bankDetails" title="Bank Details">
          <BankDetails />
        </Tab>
        <Tab eventKey="purchaseCredits" title="Purchase Credits Details">
          <PurchaseCreditsDetails />
        </Tab>
        <Tab eventKey="voteDetails" title="Vote Details">
          <VoteDetails />
        </Tab>
        <Tab eventKey="gameCurrencyDetails" title="Game Currency Details">
          <GameCurrencyDetails />
        </Tab>
      </Tabs>
    </div>
  );
}

export default WebInformation;
