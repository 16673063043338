// src/pages/Maintenance.js
// src/pages/Maintenance.js
import React, { useEffect, useState } from 'react';
import config from '../../config';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Maintenance = () => {
  const [isMaintenance, setIsMaintenance] = useState(true);

  const checkMaintenance = async () => {
    try {
      const response = await fetch(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.status}`, {
        method: 'GET',
        headers: {
          'X-Client-Info': navigator.userAgent,
        },
      });
      if (response.ok) {
        setIsMaintenance(false);
        return;
      }
    } catch (error) {
      setIsMaintenance(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(checkMaintenance, 5000);
    return () => clearInterval(interval);
  }, []);

  if (!isMaintenance) {
    window.location.reload();
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light text-center">
      <i className="fas fa-tools fa-4x text-warning mb-4"></i>
      <h1 className="display-4">Maintenance</h1>
      <p className="lead">We are currently performing maintenance. Please try again later.</p>
    </div>
  );
};

export default Maintenance;