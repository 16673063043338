import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import { useExecuteRecaptcha } from '../../util/ReCaptcha';

function ForgetPassword() {
    const [username, setUsername] = useState('');
    const [birthday, setBirthday] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const { execute } = useExecuteRecaptcha();

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try
    {
      const token = await execute('forgetpassword');
      const response = await fetch(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.forgetpassword}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
              name: username, 
              birthday: birthday,
              email: email,
              reCaptchaToken: token 
          }),
        });

        const jsonData = await response.json();
        const message = jsonData.result.message;

        if (response.ok) 
        {
            var successMessage = message + " Redirecting to login page...";
            setSuccess(successMessage);
            setSuccessMessage(true);
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        }
        else 
        {
            setError(jsonData.message || 'Failed to reset password');
        }
    }
    catch (error) 
    {
        setError('Network error');
    } 
    finally 
    {
        setLoading(false);

    }
  };


  return (
    <Container className="mt-5">
    <Row className="justify-content-md-center">
      <Col md={6}>
        <Form onSubmit={handleForgetPassword}>
          <Form.Group controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter your username" 
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBirthday">
              <Form.Label>Birthday</Form.Label>
              <Form.Control
                type="date"
                placeholder="dd/mm/yyyy"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter your email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            
            {loading ? (
              <Spinner animation="border" role="status" className="d-block mx-auto mt-3">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Button variant="primary" type="submit" className="mt-3">
                Forget Password
              </Button>
            )}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">{success}</Alert>}
            {/* {successMessage && (
              <Alert variant="info" className="mt-3">
                All unverified accounts will be deleted after 1 week.
              </Alert>
            ) */}            
        </Form>
      </Col>
    </Row>
    </Container>
  );
}

export default ForgetPassword;
