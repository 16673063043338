// src/components/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => (
  <div>
    <p>Welcome to MythicGames. By registering and using our services, you agree to abide by the following terms and conditions:</p>

    <h2>1. Ownership and Affiliation</h2>
    <p>MythicGames is a private server. All assets, including names, characters, and intellectual properties used in this server, belong to their respective companies. We do not claim ownership of these assets and are not affiliated with any of the original creators or companies.</p>

    <h2>2. Prohibited Activities</h2>
    <p>Users are strictly prohibited from engaging in any form of cheating, including but not limited to:</p>
    <ul>
      <li>Hacking</li>
      <li>Botting</li>
      <li>Using unauthorized third-party software</li>
      <li>Exploiting bugs or glitches</li>
      <li>Real-world trading (buying or selling in-game items or accounts for real money)</li>
      <li>Sharing accounts with others</li>
    </ul>
    <p>Any user found engaging in these activities will be banned permanently without prior notice.</p>

    <h2>3. Permanent Ban Policy</h2>
    <p>Users found cheating, attempting to hack the server, or engaging in real-world trading will face a permanent ban. This is non-negotiable and final.</p>

    <h2>4. Changes to Terms</h2>
    <p>MythicGames reserves the right to update or modify these terms and conditions at any time. Changes will be effective immediately upon posting. It is the responsibility of the users to stay informed of any changes.</p>

    <h2>5. Data Privacy</h2>
    <p>We take your privacy seriously. No data collected from users will be disclosed to any third party. All personal details are encrypted and securely stored. We ensure compliance with data protection regulations to safeguard your information.</p>

    <h2>6. Additional Rules</h2>
    <p>By using MythicGames, you agree to adhere to the following additional rules:</p>
    <ul>
      <li>Respect all players and staff. Harassment, abusive language, or any form of discrimination will not be tolerated.</li>
      <li>Follow in-game rules and guidelines as specified in our forums or announcements.</li>
      <li>Report any bugs, glitches, or suspicious activities to the administration immediately.</li>
    </ul>
    <p>Failure to comply with these rules may result in temporary suspension or permanent ban, depending on the severity of the violation.</p>

    <p>By registering and using MythicGames, you acknowledge that you have read, understood, and agree to these terms and conditions.</p>
  </div>
);

export default TermsAndConditions;
