// export default Vote;

import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Spinner, Alert } from 'react-bootstrap';
import config from '../../config';
import fetchWithAuth from '../../util/fetchWithAuth';
import { useExecuteRecaptcha } from '../../util/ReCaptcha';
import { useNavigate } from 'react-router-dom';

function Vote() {
  const [voteDetails, setVoteDetails] = useState([]);
  const [activeVoteSites, setActiveVoteSites] = useState([]);
  const [selectedVoteSite, setSelectedVoteSite] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [coolDown, setCoolDown] = useState(false);
  const { execute } = useExecuteRecaptcha();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVoteDetails = async () => {
      try {
        const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.voteDetails}`);

        if (response.ok) {
          const jsonData = await response.json();

          if (jsonData.result.success) {
            const voteData = Object.entries(jsonData.result.data).map(([id, details]) => ({
              id,
              ...details,
            }));
            // Filter out inactive vote sites
            const activeSites = voteData.filter(site => site.isActive);
            setVoteDetails(voteData);
            setActiveVoteSites(activeSites);
          } else {
            setError(jsonData.result.message || 'Failed to fetch vote details');
          }
        } else {
          setError('Failed to fetch vote details');
        }
      } catch (error) {
        setError('Network error');
      } finally {
        setLoading(false);
      }
    };

    fetchVoteDetails();
  }, []);

  const handleVote = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    setIsButtonDisabled(true);

    const selectedSite = activeVoteSites.find(site => site.id === selectedVoteSite);

    if (!selectedSite) {
      setError('Please select a vote site');
      setLoading(false);
      setIsButtonDisabled(false);
      return;
    }

    const reCaptchaToken = await execute('vote');
    const body = {
      voteSite: selectedSite.voteSite,
      reCaptchaToken: reCaptchaToken,
    };

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getVoteUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) {
        const message = jsonData.result.message;
        const successMessage = message + " Redirecting to vote page...";
        
        setSuccess(successMessage);
        setTimeout(() => {
          window.open(jsonData.result.url, '_blank');
          setLoading(false);
          setIsButtonDisabled(true);
        }, 2000);
      } else {
        setError(jsonData.result.message || 'Failed to get vote URL');
        setIsButtonDisabled(false);
        startCoolDown();
      }
    } catch (error) {
      setError('Network error');
      setIsButtonDisabled(false);
      startCoolDown();
    } finally {
      setLoading(false);
    }
  };

  const startCoolDown = () => {
    setCoolDown(true);
    setTimeout(() => {
      setCoolDown(false);
    }, 300000); // 5 minutes cooldown
  };

  return (
    <Container className="mt-5">
      <h1>Vote</h1>
      {loading ? (
        <Spinner animation="border" role="status" className="d-block mx-auto">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : activeVoteSites.length === 0 ? ( // Show message if no active vote sites
        <Alert variant="warning" className="text-center">
          There are currently no active games available to vote. Please check back soon for updates!
        </Alert>
      ) : (
        <>
          <Form onSubmit={handleVote} className="mt-4">
            <Form.Group controlId="formVoteSite">
              <Form.Label>Select your vote site:</Form.Label>
              <Form.Control
                as="select"
                value={selectedVoteSite}
                onChange={(e) => setSelectedVoteSite(e.target.value)}
                required
              >
                <option value="">Select a vote site</option>
                {activeVoteSites.map(site => (
                  <option key={site.id} value={site.id}>{site.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3" disabled={isButtonDisabled || coolDown}>
              {isButtonDisabled ? 'Voting...' : 'Vote'}
            </Button>
          </Form>
          
          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
          {success && <Alert variant="success" className="mt-3">{success}</Alert>}
          {coolDown && <Alert variant="warning" className="mt-3">Please wait a few minutes before trying again.</Alert>}
        </>
      )}
    </Container>
  );
}

export default Vote;
