import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import NavBar from './components/NavBar';
import Home from './pages/user/Home';
import Download from './pages/public/Download';
import Login from './pages/public/Login';
import LeftSidebar from './components/LeftSidebar';
import AccountDetails from './pages/user/AccountDetails';
import { UserProvider, UserContext } from './context/UserContext';
import config from './config';
import './App.css';
import Vote from './pages/user/Vote';
import PurchaseCredits from './pages/user/PurchaseCredits';
import CreditsHistory from './pages/user/CreditsHistory';
import RegisterAccounts from './pages/public/RegisterAccount';
import ForgetPassword from './pages/public/ForgetPassword';
import Verify from './pages/public/Verify';
import ResetPassword from './pages/public/ResetPassword';
import Maintenance from './pages/public/Maintenance'; 
import fetchWithAuth from './util/fetchWithAuth'; 
import TradeCredits from './pages/user/TradeCredits';
import MythicMS from './pages/user/MythicMS';
import ViewAllAccounts from './pages/moderator/ViewAllAccounts';
import PurchaseCreditsRequest from './pages/moderator/PurchaseCreditsRequest';
import CreatePurchaseCreditsOrder from './pages/moderator/CreatePurchaseCreditsOrder';
import WebInformation from './pages/moderator/WebInformation';
import WebServerCache from './pages/moderator/WebServerCache';
import ActuatorMetric from './pages/moderator/ActuatorMetric';
import PerformanceManager from './pages/moderator/PerformanceManager';
import MythicMSv83AdminPanel from './pages/moderator/MythicMSv83AdminPanel';

const siteKey = config.reCaptchaSiteKey; // Replace with your site key

function App() {
  return (
    <UserProvider>
      <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <Router>
          <MainApp />
        </Router>
      </GoogleReCaptchaProvider>
    </UserProvider>
  );
}

function MainApp() {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const { setUser } = useContext(UserContext);
  const { setRole } = useContext(UserContext);
  const { roleId } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {

    const checkMaintenance = async () => {
      try {
        const response = await fetch(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.status}`, {
          method: 'GET',
          headers: {
            'X-Client-Info': navigator.userAgent,
          },
        });
        if(response.ok) {
          setIsMaintenance(false);
        } else {
          setIsMaintenance(true);
        }
      } catch (error) {
        setIsMaintenance(true);
      } finally {
        setIsFirstLoad(false); // Move this here to ensure it updates after checkMaintenance
      }
    }

    const checkAuth = async () => {
      const publicRoutes = [
        '/',
        '/download',
        '/forget-password',
        '/register',
        '/reset-password',
        '/verify',        
      ];

      if (publicRoutes.includes(location.pathname)) {
        setAuthChecked(true);
        return;
      }

      const loginRoute = '/login';
      const jwtToken = sessionStorage.getItem('jwt');

      if(!jwtToken && location.pathname === loginRoute) {
        setIsLoggedIn(false);
        setAuthChecked(true);
        return;
      }

      if(jwtToken === null) {
        setIsLoggedIn(false);
        setAuthChecked(true);
        return;
      }

      try {
        const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.pingback}`);

        if (response.ok) {
          const jsonData = await response.json();
          const result = jsonData.result;
          setIsLoggedIn(true);          
          setUser(result.username);
          setRole(result.roleId); //Have to set it properly.
          console.log('User is authenticated');
        } else {
          setIsLoggedIn(false);
          console.log('User is not authenticated');
        }
      } catch (error) {
        setIsLoggedIn(false);
        console.log('Error checking authentication:', error);
      } finally {
        setAuthChecked(true); // Ensure we update authChecked even if there's an error
      }
    };

    checkMaintenance();
    checkAuth();
  }, [location.pathname, setUser]);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = async () => {
    await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.logout}`, 
    {
      method: 'POST',
    });
    setIsLoggedIn(false);
    sessionStorage.removeItem('jwt'); // Remove JWT from sessionStorage
  };

  // Show a loading message while checking authentication status
  if (isFirstLoad) {
    return (
      <div className="loading-container">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  if (isMaintenance) {
    return <Maintenance />;
  }

  return (
    <div className="app-wrapper">
      <div className="navbar-wrapper">
        <NavBar />
      </div>
      <div className="main-wrapper">
        {isLoggedIn && <LeftSidebar handleLogout={handleLogout} />}
        <div className="content">
          <Routes>
            <Route path="/" element={<Navigate to={isLoggedIn ? "/account-details" : "/login"} />} />
            <Route path="/download" element={<Download />} />
            <Route path="/register" element={<RegisterAccounts />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/login" element={isLoggedIn ? <Navigate to="/account-details" /> : <Login setIsLoggedIn={handleLogin} />} />
            <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" />}>
              <Route path="account-details" element={<AccountDetails />} />  
              <Route path="vote" element={<Vote />} />
              <Route path="purchase-credits" element={<PurchaseCredits />} />
              <Route path="credits-history" element={<CreditsHistory />} />
              <Route path ="trade-credits" element={<TradeCredits />} />
              <Route path ="mythicms" element={<MythicMS />} />
              
              {/* Moderator Path */}
              <Route path="view-all-accounts" element={roleId >= 2 ? <ViewAllAccounts /> : <Navigate to="/" />} />
              <Route path="create-purchase-credits-order" element={roleId >= 2 ? <CreatePurchaseCreditsOrder /> : <Navigate to="/" />} />
              <Route path="purchase-credits-request" element={roleId >= 4 ? <PurchaseCreditsRequest /> : <Navigate to="/" />} />
              <Route path="web-information" element={roleId >= 4 ? <WebInformation /> : <Navigate to="/" />} />
              <Route path="web-server-cache" element={roleId >= 4 ? <WebServerCache /> : <Navigate to="/" />} />
              <Route path="actuator-metric" element={roleId >= 4 ? <ActuatorMetric /> : <Navigate to="/" />} />
              <Route path="performance-manager" element={roleId >= 4 ? <PerformanceManager /> : <Navigate to="/" />} />
              <Route path="mythicms-v83-admin-panel" element={roleId >= 4 ? <MythicMSv83AdminPanel /> : <Navigate to="/" />} />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
