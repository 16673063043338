import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import config from '../../config';
import fetchWithAuth from '../../util/fetchWithAuth';
import { Container, Row, Col, Spinner, Alert, Form, Button, Card } from 'react-bootstrap';
import { useExecuteRecaptcha } from '../../util/ReCaptcha';
import './css/AccountDetails.css';

function AccountDetails() {
  const [accountDetails, setAccountDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [updateOption, setUpdateOption] = useState('email');
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [refreshDisabled, setRefreshDisabled] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [cooldownTime, setCooldownTime] = useState(0);
  const navigate = useNavigate();
  const { setUser, setRole } = useContext(UserContext);
  const { execute } = useExecuteRecaptcha();

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  useEffect(() => {
    if (cooldownTime > 0) {
      const timer = setInterval(() => {
        setCooldownTime(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setRefreshCounter(0);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [cooldownTime]);

  const fetchAccountDetails = async () => {
    setLoading(true);
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.details}`);

      if (response.ok) {
        const jsonData = await response.json();

        if (jsonData.result.success) {
          setAccountDetails(jsonData.result);
        } else {
          setError(jsonData.result.message || 'Failed to fetch account details');
          handleLogout();
        }
      } else {
        setError('Failed to fetch account details');
        handleLogout();
      }
    } catch (error) {
      setError('Network error');
      handleLogout();
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.logout}`, {
      method: 'POST',
    });
    setUser(null);
    setRole(null);
    sessionStorage.removeItem('jwt');
    navigate('/login');
  };

  const handleUpdateAccount = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const reCaptchaToken = await execute('updateAccount');
    let endpoint = '';
    let body = {};

    if (updateOption === 'email') {
      endpoint = config.endpoints.changeEmail;
      body = {
        newEmail: newEmail,
        currentPassword: currentPassword,
        reCaptchaToken: reCaptchaToken,
      };
    } else if (updateOption === 'password') {
      if (newPassword !== confirmPassword) {
        setError('Passwords do not match');
        setLoading(false);
        return;
      }
      endpoint = config.endpoints.changePassword;
      body = {
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
        reCaptchaToken: reCaptchaToken,
      };
    }

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const jsonData = await response.json();
      const message = jsonData.result.message;

      if (response.ok) {
        if (jsonData.result.success) {
          setSuccess(message);
          if (updateOption === 'email') {
            setAccountDetails((prevDetails) => ({
              ...prevDetails,
              email: newEmail,
            }));
          }
          setTimeout(() => {
            setShowUpdateForm(false);
            setSuccess('');
          }, 2000);
        } else {
          setError(message || 'Failed to update account');
        }
      } else {
        setError(message || 'Failed to update account');
      }
    } catch (error) {
      setError('Fail to update account, please relogin and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    if (refreshCounter >= 3) {
      setError("Too many refreshes, please wait a few minutes before trying again.");
      setCooldownTime(3 * 60); // 3 minutes cooldown
      return;
    }

    setRefreshDisabled(true);
    setRefreshCounter(refreshCounter + 1);

    setTimeout(async () => {
      await fetchAccountDetails();
      setRefreshDisabled(false);
    }, 5000);
  };

  if (loading && !accountDetails) {
    return (
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col md={6} className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  }

  if (error && !accountDetails) {
    return (
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col md={6}>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="mt-5 account-details-container">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="p-4 account-details-content">
          <h1>Account Details
              <Button
                variant="link"
                onClick={handleRefresh}
                disabled={refreshDisabled}
                className="ml-2 refresh-button"
              >
                {refreshDisabled ? <Spinner as="span" animation="border" size="sm" /> : <div className='refresh-button-icon'></div>}
              </Button>               
            </h1>
            <p><strong>Name:</strong> {accountDetails.name}</p>
            <p><strong>Join Date:</strong> {new Date(accountDetails.createdAt).toLocaleDateString()}</p>
            <p><strong>Birthday:</strong> {new Date(accountDetails.birthday).toLocaleDateString()}</p>
            <p><strong>Credits:</strong> {accountDetails.credits}</p>              
            <p><strong>Email:</strong> {accountDetails.email}</p>
            <Button variant="primary" onClick={() => setShowUpdateForm(!showUpdateForm)} className="mb-3">
              {showUpdateForm ? 'Cancel' : 'Update Account'}
            </Button>
            {showUpdateForm && (
              <Form onSubmit={handleUpdateAccount} className="mt-4">
                <Form.Group controlId="formUpdateOption">
                  <Form.Label>Update Option <i className="fas fa-caret-down dropdown-icon"></i></Form.Label>
                  <Form.Control
                    as="select"
                    value={updateOption}
                    onChange={(e) => setUpdateOption(e.target.value)}
                    required
                    className="update-option"
                  >
                    <option value="email">Update Email</option>
                    <option value="password">Update Password</option>
                  </Form.Control>
                </Form.Group>

                {updateOption === 'email' && (
                  <>
                    <Form.Group controlId="formNewEmail">
                      <Form.Label>New Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your new email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formCurrentPassword">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter your current password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </>
                )}

                {updateOption === 'password' && (
                  <>
                    <Form.Group controlId="formCurrentPassword">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter your current password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formNewPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter your new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword">
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm your new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </>
                )}

                {loading ? (
                  <Spinner animation="border" role="status" className="d-block mx-auto mt-3">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <Button variant="primary" type="submit" className="mt-3">
                    Update Account
                  </Button>
                )}
              </Form>
            )}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">{success}</Alert>}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AccountDetails;
