import React, { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { Navbar, Nav, Collapse, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './css/LeftSidebar.css'; // Make sure to create this CSS file

function LeftSidebar({ handleLogout }) {
  const { username, roleId } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavLinkClick = (path) => {
    setOpen(false);
    navigate(path);
  };

  return (
    <>
      {/* Navbar for mobile devices */}
      <Navbar bg="light" expand="lg" className="d-lg-none">
        <Button variant="outline-secondary" onClick={() => setOpen(!open)} aria-controls="sidebar-navbar-nav-responsive" aria-expanded={open} className="sidebar-toggler">
          <span className="navbar-toggler-icon"></span>
        </Button>
        <Collapse in={open} className="position-absolute bg-light" id="sidebar-navbar-nav-responsive">

          <Nav className="flex-column" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
            <h4 className="text-left mt-1">Welcome, {username}!</h4>
            <hr />
            <Nav.Item>
              <h5 className="nav-header">Accounts</h5>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleNavLinkClick('/account-details')} className="nav-link">Account details</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <h5 className="nav-header mt-1">Credits</h5>
            </Nav.Item>
            
            <Nav.Item>
              <Nav.Link onClick={() => handleNavLinkClick('/trade-credits')} className="nav-link">Trade credits</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleNavLinkClick('/purchase-credits')} className="nav-link">Purchase credits</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleNavLinkClick('/vote')} className="nav-link">Vote</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleNavLinkClick('/credits-history')} className="nav-link">Credits history</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <h5 className="nav-header mt-1">Game</h5>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/mythicms" className="nav-link">MythicMS</Nav.Link>
            </Nav.Item>
            
            {roleId >= 2 && (
            <>
              <Nav.Item>
                <h5 className="nav-header">Admin Panel</h5>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => handleNavLinkClick('/view-all-accounts')} className="nav-link">View All Accounts</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => handleNavLinkClick('/create-purchase-credits-order')} className="nav-link">Create Purchase Credits Order</Nav.Link>
              </Nav.Item>
              {roleId >= 4 && (
                  <>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleNavLinkClick('/purchase-credits-request')} className="nav-link">Purchase Credits Request</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleNavLinkClick('/web-information')} className="nav-link">Web Information</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleNavLinkClick('/web-server-cache')} className="nav-link">Web Server Cache</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleNavLinkClick('/mythicms-v83-admin-panel')} className="nav-link">MythicMS-v83</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <h5 className="nav-header">System Tool</h5>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link onClick={() => handleNavLinkClick('/actuator-metric')} className="nav-link">Server Actuator Metric</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => handleNavLinkClick('/performance-manager')} className="nav-link">Performance Manager</Nav.Link>
                    </Nav.Item>
                </>
                // <Nav.Item>
                //   <Nav.Link onClick={() => handleNavLinkClick('/purchase-credits-request')} className="nav-link">Purchase Credits Request</Nav.Link>
                // </Nav.Item>
              )}
            </>
          )}

            <Nav.Item>
              <hr className="my-2" />
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={handleLogout} className="nav-link mb-5">Sign out</Nav.Link>
            </Nav.Item>
          </Nav>
        </Collapse>
      </Navbar>

      {/* Sidebar for larger screens */}
      <div className="left-sidebar d-none d-lg-block">
        <h4 className="text-left mt-1">Welcome, {username}!</h4>
        <hr />

        <Nav defaultActiveKey="" className="flex-column" style={{ overflowY: 'auto' }}>
          <Nav.Item>
            <h5 className="nav-header">Accounts</h5>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/account-details" className="nav-link">Account details</Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <h5 className="nav-header mt-1">Credits</h5>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link onClick={() => handleNavLinkClick('/trade-credits')} className="nav-link">Trade credits</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => handleNavLinkClick('/purchase-credits')} className="nav-link">Purchase credits</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => handleNavLinkClick('/vote')} className="nav-link">Vote</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => handleNavLinkClick('/credits-history')} className="nav-link">Credits history</Nav.Link>
          </Nav.Item>

          <Nav.Item>
              <h5 className="nav-header mt-1">Game</h5>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/mythicms" className="nav-link">MythicMS</Nav.Link>
            </Nav.Item>

          {roleId >= 2 && (
            <>
              <Nav.Item>
                <h5 className="nav-header">Admin Panel</h5>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => handleNavLinkClick('/view-all-accounts')} className="nav-link">View All Accounts</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => handleNavLinkClick('/create-purchase-credits-order')} className="nav-link">Create Purchase Credits Order</Nav.Link>
              </Nav.Item>
              {roleId >= 4 && (
                  <>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleNavLinkClick('/purchase-credits-request')} className="nav-link">Purchase Credits Request</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleNavLinkClick('/web-information')} className="nav-link">Web Information</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleNavLinkClick('/web-server-cache')} className="nav-link">Web Server Cache</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleNavLinkClick('/mythicms-v83-admin-panel')} className="nav-link">MythicMS-v83</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <h5 className="nav-header">System Tool</h5>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link onClick={() => handleNavLinkClick('/actuator-metric')} className="nav-link">Server Actuator Metric</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => handleNavLinkClick('/performance-manager')} className="nav-link">Performance Manager</Nav.Link>
                    </Nav.Item>
                </>
                // <Nav.Item>
                //   <Nav.Link onClick={() => handleNavLinkClick('/purchase-credits-request')} className="nav-link">Purchase Credits Request</Nav.Link>
                // </Nav.Item>
              )}
            </>
          )}
          <Nav.Item>
            <hr className="my-2" />
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={handleLogout} className="nav-link">Sign out</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </>
  );
}

export default LeftSidebar;