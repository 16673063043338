const config = {
  httpsProtocol: 'https://',
  // httpsProtocol: 'http://',
  wssProtocol: 'wss://',
  apiBaseUrl: 'mythicgames.me',
  // apiBaseUrl: 'localhost:8080',
  endpoints: {
    pingback:  '/api/pingback/ping',
    status: '/api/pingback/status',
    register: '/api/account/register', 
    verify: '/api/account/verify',
    forgetpassword: '/api/account/forget-password',
    resetpassword: '/api/account/reset-password',
    login: '/api/account/login',
    submitOtp: '/api/account/submit-otp',
    resendOtp: '/api/account/resend-otp',
    logout: '/api/account/logout',
    details: '/api/account/details',
    changeEmail: '/api/account/change-email',
    changePassword: '/api/account/change-password',
    voteDetails: '/api/vote/details',
    updateVoteDetails: '/api/vote/update',
    updateVoteActiveDetails: '/api/vote/update-active',
    addVoteDetails: '/api/vote/add',
    deleteVoteDetails: '/api/vote/delete',
    getVoteUrl: '/api/vote/get-url',
    getCreditDetails: '/api/credit/details',
    updateCreditDetails: '/api/credit/update',
    addCreditDetails: '/api/credit/add',
    deleteCreditDetails: '/api/credit/delete',
    purchaseCredits: '/api/credit/purchase-credits',
    getBankDetails: '/api/bank/details',
    updateBankDetails: '/api/bank/update',
    addBankDetails: '/api/bank/add',
    deleteBankDetails: '/api/bank/delete',
    completePurchase: '/api/credit/complete-purchase',
    cancelPurchase: '/api/credit/cancel-purchase',
    getPendingPurchaseHistory: '/api/credit/get-pending-purchase-history',
    getVoteHistory: '/api/vote/get-vote-history',
    getCreditHistory: '/api/credit/get-credit-history',
    getGameCurrencyDetails: '/api/credit/game-currency-details',
    updateGameCurrencyDetails: '/api/credit/update-game-currency-details',
    updateGameCurrencyActiveDetails: '/api/credit/update-game-currency-active-details',
    tradeCredits: '/api/credit/trade-credits',
    viewAllAccounts: '/api/admin/get-all-accounts',
    getLoginAttemptCache: '/api/admin/get-login-attempt-cache',
    cleanExpiredAttempts: '/api/admin/clean-expired-login-attempts',
    clearAttempts: '/api/admin/clear-login-attempts',
    getOtpCache: '/api/admin/get-otp-cache',
    cleanExpiredOtp: '/api/admin/clean-expired-otp',
    removeOtp: '/api/admin/remove-otp',
    adminResendOtp: '/api/admin/resend-otp',
    regenerateOtp: '/api/admin/regenerate-otp',
    overrideVerify: '/api/admin/override-verify',
    resendVerificationCode: '/api/admin/resend-verification-code',
    deleteAccount: '/api/admin/delete-account',
    updateAccount: '/api/admin/update-account',
    getUserCreditHistory: '/api/admin/get-user-credit-history',
    getTargetedCreditHistory: '/api/admin/get-targeted-credit-history',
    getPurchaseCreditsRequest: '/api/admin/get-purchase-credits-request',
    updatePurchaseCreditsRequest: '/api/admin/update-purchase-credits-request',
    getVerifiedAccounts: '/api/admin/get-all-verified-accounts',
    createPurchaseCreditsOrder: '/api/admin/create-purchase-credits-order',
    getMetrics: '/api/monitoring/get-metrics',
    getMetric: '/api/monitoring/get-metric',
    getHealth: '/api/monitoring/get-health',
    terminateProcess: '/api/monitoring/terminate',
    performanceMetrics: '/api/monitoring/performance-metrics',
    truncateMythicMSv83Database: '/api/admin/truncate-mythicmsv83-database',
    updateMythicMSv83CharacterToGmLevel: '/api/admin/update-mythicmsv83-character-to-gm-level',
    updateMythicMSv83UnstuckCharacter: '/api/admin/update-mythicmsv83-unstuck-character',
    // Add more endpoints here as needed
  },
  websocket:
  {
    processes: '/ws/processes?token=',
  },
  reCaptchaSiteKey: '6LfDewgqAAAAAELkl40VdaN0eJps_UZoqVzin9nm',
};

export default config;
