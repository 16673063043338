export const validateBirthday = (birthday) => {
    let errorMessage = '';

    const [year, month, day] = birthday.split('-').map(Number);

    // Check if the date is in the past
    const today = new Date();
    const birthDate = new Date(year, month - 1, day); // months are 0-indexed

    if (birthDate >= today) {
    errorMessage += 'Birthday must be in the past. ';
    return errorMessage.trim();
    }

    // Check if the user is at least 13 years old and not older than 100 years
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    const isAtLeast13 = age > 13 || (age === 13 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));
    const isNotOlderThan100 = age < 100 || (age === 100 && (monthDiff < 0 || (monthDiff === 0 && dayDiff <= 0)));

    if (!isAtLeast13) {
    errorMessage += 'You must be at least 13 years old. ';
    }

    if (!isNotOlderThan100) {
    errorMessage += 'You must be less than 100 years old. ';
    }
  
    return errorMessage.trim();
  };
  