import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Spinner, Alert, Modal } from 'react-bootstrap';
import config from '../../config';
import fetchWithAuth from '../../util/fetchWithAuth';
import { useExecuteRecaptcha } from '../../util/ReCaptcha';

function TradeCredits() {
  const [gameCurrencyDetails, setGameCurrencyDetails] = useState([]);
  const [activeGames, setActiveGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(''); // This controls the visibility of other form elements
  const [selectedRate, setSelectedRate] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [userCredits, setUserCredits] = useState(0);
  const [tradeCredits, setTradeCredits] = useState(0);
  const [computedAmount, setComputedAmount] = useState(0);
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { execute } = useExecuteRecaptcha();

  useEffect(() => {
    const fetchCurrencyDetails = async () => {
      setLoading(true);
      try {
        const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getGameCurrencyDetails}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const jsonData = await response.json();

        if (response.ok && jsonData.result.success) {
          const activeGamesList = jsonData.result.data.filter(game => game.isActive);
          setGameCurrencyDetails(jsonData.result.data);
          setActiveGames(activeGamesList);
        } else {
          setError(jsonData.result.message || 'Failed to fetch game currency details');
        }
      } catch (error) {
        setError('Network error. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    const fetchUserCredits = async () => {
      try {
        const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.details}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const jsonData = await response.json();

        if (response.ok && jsonData.result.success) {
          setUserCredits(jsonData.result.credits);
          setRemainingCredits(jsonData.result.credits); // Initially set remaining credits to total user credits
        } else {
          setError(jsonData.result.message || 'Failed to fetch user credits');
        }
      } catch (error) {
        setError('Network error. Please try again later.');
      }
    };

    fetchCurrencyDetails();
    fetchUserCredits();
  }, []);

  useEffect(() => {
    if (selectedGame) {
      const selectedGameDetails = gameCurrencyDetails.find(game => game.name === selectedGame);
      if (selectedGameDetails) {
        setSelectedRate(selectedGameDetails.currencyRates);
        setSelectedCurrency(selectedGameDetails.currencyType);
        setComputedAmount(Math.round(tradeCredits * selectedGameDetails.currencyRates));
      }
    }
  }, [selectedGame, tradeCredits, gameCurrencyDetails]);

  const handleTradeCreditsSliderChange = (value) => {
    // Ensure the value is a multiple of 50 when using the slider
    const roundedValue = Math.round(value / 50) * 50;
    setTradeCredits(roundedValue);
    setComputedAmount(Math.round(roundedValue * selectedRate));
    setRemainingCredits(userCredits - roundedValue);
  };
  
  const handleTradeCreditsInputChange = (e) => {
    const value = e.target.value === "" ? 0 : parseInt(e.target.value, 10); // Allow empty value to be handled properly
    if (!isNaN(value)) {
      setTradeCredits(value);
      setComputedAmount(Math.round(value * selectedRate));
      setRemainingCredits(userCredits - value);
    }
  };

  const handleTradeSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const token = await execute('tradeCredit');
    const body = {      
      credits: tradeCredits,
      computedAmount: computedAmount,
      currencyType: selectedCurrency,
      currencyRates: selectedRate,
      destination: selectedGame,
      reCaptchaToken: token
    };

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.tradeCredits}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) {
        setSuccess('Trade successful');
        setUserCredits(prevCredits => prevCredits - tradeCredits);
        setRemainingCredits(prevRemaining => prevRemaining - tradeCredits); // Update remaining credits after trade
      } else {
        setError(jsonData.result.message || 'Failed to complete trade');
      }
    } catch (error) {
      setError('Network error. Please try again later.');
    } finally {
      setLoading(false);
      setShowConfirmModal(false);
    }
  };

  return (
    <Container className="mt-5">
      <h1>Trade Credits</h1>
      {loading ? (
        <Spinner animation="border" role="status" className="d-block mx-auto">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : success ? (
        <Alert variant="success" className="text-center">
          {success}
        </Alert>
      ) : userCredits === 0 ? (
        <Alert variant="warning" className="text-center">
          You do not have any credits. Please vote or purchase credits to trade for game currency.
        </Alert>
      ) : activeGames.length === 0 ? (
        <Alert variant="warning" className="text-center">
          There are currently no active games available to trade credits. Please check back soon for updates!
        </Alert>
      ) : (
        <Form>
          <Form.Group controlId="formGameSelection">
            <Form.Label>Select Game</Form.Label>
            <Form.Control as="select" value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)} required>
              <option value="">Select a game</option>
              {activeGames.map(game => (
                <option key={game.id} value={game.name}>
                  {game.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Only show these elements if a game is selected */}
          {selectedGame && (
            <>
              <Form.Group controlId="formTradeCredits" className="mt-3">
                <Form.Label>Trade Credits</Form.Label>
                
                {/* Slider Input */}
                <Form.Control
                  type="range"
                  min="0"
                  max={userCredits}
                  step="50"
                  value={tradeCredits}
                  onChange={(e) => handleTradeCreditsSliderChange(Number(e.target.value))}  // Slider updates
                />
                
                {/* Number Input */}
                <Form.Control
                  type="number"
                  min="0"
                  max={userCredits}
                  step="50"
                  value={tradeCredits}
                  onChange={handleTradeCreditsInputChange}  // Number input updates without rounding
                />
              </Form.Group>



              <Form.Group controlId="formComputedAmount" className="mt-3">
                <Form.Label>Computed Amount ({selectedCurrency})</Form.Label>
                <Form.Control type="text" value={computedAmount} readOnly />
              </Form.Group>

              <Form.Group controlId="formRemainingCredits" className="mt-3">
                <Form.Label>Total Available Credits: {userCredits}</Form.Label><br/>
                <Form.Label>Remaining Credits after trade: {remainingCredits}</Form.Label>
              </Form.Group>

              <Button
                variant="primary"
                className="mt-3"
                onClick={() => setShowConfirmModal(true)}
                disabled={!selectedGame || !tradeCredits}
              >
                Trade
              </Button>
            </>
          )}
        </Form>
      )}

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Trade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You are trading your credits for {selectedGame}</p>
          <p>{tradeCredits} credits for {computedAmount} {selectedCurrency}</p>
          <p>This transaction is irreversible. Are you sure you want to proceed?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleTradeSubmit}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default TradeCredits;
