import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Spinner, Alert } from 'react-bootstrap';
import config from '../../config';
import fetchWithAuth from '../../util/fetchWithAuth';

function CreatePurchaseCreditsOrder() {
  const [creditDetails, setCreditDetails] = useState([]);
  const [selectedCredit, setSelectedCredit] = useState('');
  const [userAccounts, setUserAccounts] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [searchUserTerm, setSearchUserTerm] = useState(''); // New state for user search term
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const creditResponse = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getCreditDetails}`);
        const userResponse = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getVerifiedAccounts}`);

        if (creditResponse.ok && userResponse.ok) {
          const creditData = await creditResponse.json();
          const userData = await userResponse.json();

          if (creditData.result.success && userData.result.success) {
            const credits = Object.entries(creditData.result.data).map(([id, details]) => ({
              id,
              ...details,
            }));
            setCreditDetails(credits);

            setUserAccounts(userData.result.verifiedAccounts);
          } else {
            setError('Failed to fetch initial data');
          }
        } else {
          setError('Failed to fetch initial data');
        }
      } catch (error) {
        setError('Network error');
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setSelectedCredit(value);
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleSearchUserTermChange = (event) => {
    setSearchUserTerm(event.target.value.toLowerCase());
  };

  const handlePurchase = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const selected = creditDetails.find((credit) => credit.id === selectedCredit);

    if (!selected) {
      setError('Please select a credit option');
      setLoading(false);
      return;
    }

    if (!selectedUser) {
      setError('Please select a user');
      setLoading(false);
      return;
    }

    const body = {
      targetAccountId: selectedUser,
      targetCreditId: selected.id,
      targetPrice: selected.price,
      targetCredits: selected.credits,
    };

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.createPurchaseCreditsOrder}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) {
        setSuccess(jsonData.result.message || 'Successfully purchased credits');
        setSelectedCredit('');
        setSelectedUser('');
      } else {
        setError(jsonData.result.message || 'Failed to purchase credits');
      }
    } catch (error) {
      setError('Network error');
    } finally {
      setLoading(false);
    }
  };

  const formatPrice = (price) => `SGD $${Number(price).toFixed(2)}`;
  const formatCredits = (credits) => (credits ? credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A');

  // Filter users based on search term
  const filteredUsers = userAccounts.filter((user) =>
    user.name.toLowerCase().includes(searchUserTerm)
  );

  return (
    <Container className="purchase-credits-container">
      <div className="purchase-credits-card">
        <h1>Creating Order for {selectedUser && userAccounts.find(user => user.id === selectedUser)?.name}</h1>
        {loading ? (
          <Spinner animation="border" role="status" className="d-block mx-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Form onSubmit={handlePurchase} className="mt-4">
            <Form.Group>
            <Form.Label>Select User</Form.Label>
              <Form.Control
                type="text"
                placeholder="Filter by username"
                value={searchUserTerm}
                onChange={handleSearchUserTermChange}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Control as="select" value={selectedUser} onChange={handleUserChange}>
                {/* <option value="">Select a user</option> */}
                {filteredUsers.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Select Credits</Form.Label>
              {creditDetails.map((credit) => (
                <Form.Check
                  type="radio"
                  key={credit.id}
                  id={`credit-${credit.id}`}
                  label={`${formatPrice(credit.price)} - ${formatCredits(credit.credits)} credits`}
                  value={credit.id}
                  checked={selectedCredit === credit.id}
                  onChange={handleRadioChange}
                  className="form-check"
                />
              ))}
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Create Purchase Credits Order for User
            </Button>
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">{success}</Alert>}
          </Form>
        )}
      </div>
    </Container>
  );
}

export default CreatePurchaseCreditsOrder;
