import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Modal, Alert } from 'react-bootstrap';
import fetchWithAuth from '../../../util/fetchWithAuth';
import config from '../../../config';
import ReactPaginate from 'react-paginate'; // Import for pagination

function GameCurrencyDetails() {
  const [currencyDetails, setCurrencyDetails] = useState([]);
  const [editingCurrencyId, setEditingCurrencyId] = useState(null); // To track the currency being edited
  const [editedCurrencyRates, setEditedCurrencyRates] = useState(''); // Store the edited currency rates
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const [itemsPerPage] = useState(20); // Number of items per page
  const [error, setError] = useState(''); // State to hold error messages
  const [showErrorModal, setShowErrorModal] = useState(false); // Control error modal

  useEffect(() => {
    fetchCurrencyDetails();
  }, []);

  const fetchCurrencyDetails = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getGameCurrencyDetails}`);
      if (response.ok) {
        const jsonData = await response.json();
        const currencyData = jsonData.result.data;

        // Convert the currencyData object into an array of objects with id, name, currencyRates, currencyType, and isActive
        const currencyArray = Object.entries(currencyData).map(([id, details]) => ({
          id,
          ...details,
        }));

        // Ensure currencyRates always has two decimal places for display
        const formattedCurrencyArray = currencyArray.map(currency => ({
          ...currency,
          currencyRates: parseFloat(currency.currencyRates).toFixed(2),
        }));

        setCurrencyDetails(formattedCurrencyArray); // Store the transformed array in state
      } else {
        throw new Error('Error fetching game currency details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleError = (message) => {
    setError(message);
    setShowErrorModal(true);
  };

  const handleUpdateCurrency = (currency) => {
    setEditingCurrencyId(currency.id);
    setEditedCurrencyRates(currency.currencyRates);
  };

  const handleSaveUpdate = async (id) => {
    const formattedCurrencyRates = parseFloat(editedCurrencyRates).toFixed(2);

    // Validate if it's a proper decimal value with up to 2 decimal places
    if (isNaN(formattedCurrencyRates)) {
      handleError('Currency Rates must be a valid decimal number');
      return;
    }

    const updatedCurrency = currencyDetails.find(currency => currency.id === id);

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updateGameCurrencyDetails}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          currencyRates: formattedCurrencyRates,
          name: updatedCurrency.name, // Including name
          currencyType: updatedCurrency.currencyType, // Including currencyType
        }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          fetchCurrencyDetails(); // Refresh list after saving
          setEditingCurrencyId(null); // Exit editing mode
        } else {
          handleError(jsonData.result.message || 'Error updating game currency details');
        }
      } else {
        throw new Error('Error updating game currency details');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleCancelEdit = () => {
    setEditingCurrencyId(null); // Cancel the editing process
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleCurrencyInput = (e) => {
    // Allow only valid decimal values and restrict to two decimal places
    let value = e.target.value;

    if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
      setEditedCurrencyRates(value);
    }
  };

  const toggleIsActive = async (id, isActive) => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.updateGameCurrencyActiveDetails}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          isActive: !isActive, // Toggle the current state
        }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.result.success) {
          fetchCurrencyDetails(); // Refresh the data after updating
        } else {
          handleError(jsonData.result.message || 'Error updating game currency active status');
        }
      } else {
        throw new Error('Error updating game currency active status');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const paginatedCurrencies = currencyDetails.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Currency Rates</th>
            <th>Currency Type</th>
            <th>Status</th> {/* New column for isActive status */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedCurrencies.length > 0 ? (
            paginatedCurrencies.map((currency) => (
              <tr key={currency.id}>
                <td>{currency.name}</td>
                <td>
                  {editingCurrencyId === currency.id ? (
                    <Form.Control
                      type="number"
                      step="0.01"
                      value={editedCurrencyRates}
                      onChange={handleCurrencyInput}
                    />
                  ) : (
                    currency.currencyRates
                  )}
                </td>
                <td>{currency.currencyType}</td>
                <td>
                  {currency.isActive ? 'Active' : 'Inactive'}
                </td>
                <td>
                  {editingCurrencyId === currency.id ? (
                    <>
                      <Button variant="success" onClick={() => handleSaveUpdate(currency.id)}>
                        Save
                      </Button>
                      <Button variant="secondary" onClick={handleCancelEdit} style={{ marginLeft: '10px' }}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="warning"
                        style={{ marginRight: '10px' }}
                        onClick={() => handleUpdateCurrency(currency)}
                      >
                        Update
                      </Button>
                      <Button
                        variant={currency.isActive ? 'danger' : 'success'}
                        onClick={() => toggleIsActive(currency.id, currency.isActive)}
                      >
                        {currency.isActive ? 'Disable' : 'Enable'}
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No game currency details available
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination */}
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={Math.ceil(currencyDetails.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center mt-3'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        disabledClassName={'disabled'}
      />

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">{error}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default GameCurrencyDetails;
