import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './css/MythicMS.css'; // Make sure to create this CSS file

function MythicMS() {
  return (
    <Container className="mythicms-container mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>MythicMS Player Statistics (Subjected to Change)</Card.Title>
              <Card.Text>
                Work in progress...
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default MythicMS;
