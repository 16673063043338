import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import fetchWithAuth from '../../../util/fetchWithAuth';
import config from '../../../config';

function Processes() {
  const [processes, setProcesses] = useState([]);
  const [ws, setWs] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'cpu_usage', direction: 'desc' });
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [secondaryPassword, setSecondaryPassword] = useState('');
  const [selectedPid, setSelectedPid] = useState(null);
  const maxReconnectAttempts = 5;

  useEffect(() => {
    const token = sessionStorage.getItem('jwt');  // Retrieve JWT token from session storage

    const connectWebSocket = () => {
      const socket = new WebSocket(`${config.wssProtocol}${config.apiBaseUrl}${config.websocket.processes}${token}`);
      setWs(socket);

      socket.onopen = () => {
        console.log('WebSocket connection opened');
        socket.send(JSON.stringify({ type: 'INITIAL_REQUEST', data: 'Requesting processes' }));
        setReconnectAttempts(0); // Reset reconnect attempts on successful connection
      };

      socket.onmessage = (message) => {
        const data = JSON.parse(message.data);
        setProcesses(data);
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed');
        // Try to reconnect if the connection closes
        if (reconnectAttempts < maxReconnectAttempts) {
          setTimeout(() => {
            setReconnectAttempts((prev) => prev + 1);
            connectWebSocket();
          }, 3000); // Reconnect after 3 seconds
        } else {
          console.log('Max reconnect attempts reached.');
          showModalMessage('Unable to establish a WebSocket connection.');
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error: ', error);
        showModalMessage('An error occurred with the WebSocket connection.');
      };

      return () => {
        socket.close();
      };
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [reconnectAttempts]);

  // Handle modal close
  const handleCloseModal = () => setShowModal(false);

  // Show modal with custom message
  const showModalMessage = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  // Open secondary password modal
  const handleShowPasswordModal = (pid) => {
    setSelectedPid(pid);
    setShowPasswordModal(true);
  };

  // Close secondary password modal
  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
    setSecondaryPassword('');
  };

  // Confirm and terminate the process with the secondary password
  const handleConfirmTerminate = async () => {
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.terminateProcess}/${selectedPid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ secondaryPassword }),
      });

      if (response.ok) {
        showModalMessage(`Process ${selectedPid} terminated successfully.`);
      } else {
        showModalMessage(`Failed to terminate process ${selectedPid}.`);
      }
    } catch (error) {
      console.error('Error terminating process:', error);
      showModalMessage('An error occurred while trying to terminate the process.');
    } finally {
      handleClosePasswordModal();
    }
  };

  // Filter processes by search term
  const filteredProcesses = processes.filter(process =>
    process.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort processes based on column
  const sortedProcesses = [...filteredProcesses].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Handle sorting when a column header is clicked
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Render sorting indicator
  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' ▲' : ' ▼'; // Add up/down arrow based on the direction
    }
    return '';
  };

  return (
    <>
      <Form.Control
        type="text"
        placeholder="Search by Process Name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort('pid')}>
              PID {renderSortIcon('pid')}
            </th>
            <th onClick={() => handleSort('name')}>
              Process Name {renderSortIcon('name')}
            </th>
            <th onClick={() => handleSort('cpu_usage')}>
              CPU Usage {renderSortIcon('cpu_usage')}
            </th>
            <th onClick={() => handleSort('memory')}>
              Memory (MB) {renderSortIcon('memory')}
            </th>
            <th onClick={() => handleSort('threads')}>
              Threads {renderSortIcon('threads')}
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {sortedProcesses.map((process) => (
            <tr key={process.pid}>
              <td>{process.pid}</td>
              <td>{process.name}</td>
              <td>{process.cpu_usage.toFixed(2)}%</td>
              <td>{process.memory}</td>
              <td>{process.threads}</td>
              <td>
                <Button variant="danger" onClick={() => handleShowPasswordModal(process.pid)}>
                  End Task
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for showing messages */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Task Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for secondary password input */}
      <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Secondary Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Secondary Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter secondary password"
              value={secondaryPassword}
              onChange={(e) => setSecondaryPassword(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmTerminate}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Processes;