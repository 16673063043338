import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Spinner, Alert, ListGroup } from 'react-bootstrap';
import fetchWithAuth from '../../util/fetchWithAuth';
import config from '../../config';

function ActuatorMetric() {
  const [metrics, setMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('');
  const [metricData, setMetricData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [healthLoading, setHealthLoading] = useState(true);
  const [healthData, setHealthData] = useState(null);
  const [error, setError] = useState('');
  const [metricLoading, setMetricLoading] = useState(false);

  // Fetch system health on initial load
  useEffect(() => {
    const fetchHealth = async () => {
      try {
        const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getHealth}`);
        const data = await response.json();
        if (data.error) {
          setError(data.error); // Capture error from the health data
        } else {
          setHealthData(data.result.data); // Assuming health data is in "result" field
        }
        setHealthLoading(false);
      } catch (err) {
        setError('Failed to fetch health data');
        setHealthLoading(false);
      }
    };

    const fetchMetrics = async () => {
      try {
        const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getMetrics}`);
        const data = await response.json();
        setMetrics(data.result.data.names || []); // Assuming the metrics are in "names" field
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch metrics list');
        setLoading(false);
      }
    };

    fetchHealth();
    fetchMetrics();
  }, []);

  // Function to handle metric click
  const handleMetricClick = async (metricName) => {
    setMetricLoading(true);
    setSelectedMetric(metricName);
    setMetricData(null); // Reset previous metric data
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getMetric}/${metricName}`);
      const data = await response.json();
      setMetricData(data.result.data);
    } catch (err) {
      setError('Failed to fetch metric data');
    } finally {
      setMetricLoading(false);
    }
  };

  // Helper function to convert nanoseconds to DD HH MM SS MS format
  const formatTime = (nanoseconds) => {
    const ms = nanoseconds / 1e6; // Convert nanoseconds to milliseconds
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    const hours = Math.floor((ms % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((ms % (60 * 1000)) / 1000);
    const milliseconds = Math.floor(ms % 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s ${milliseconds}ms`;
  };

  // Helper to format bytes into KB, MB, GB, etc.
  const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Helper function to beautify key metric details
  const renderBeautifiedMetrics = () => {
    if (!metricData) return null;

    const { name, description, measurements, baseUnit } = metricData;

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Key Metrics</Card.Title>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>Metric Name:</strong> {name || 'N/A'}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Description:</strong> {description || 'No description available'}
            </ListGroup.Item>
            {measurements && measurements.length > 0 && (
              <ListGroup.Item>
                <strong>Measurements:</strong>
                <ul>
                  {measurements.map((measurement, index) => {
                    let formattedValue = measurement.value.toFixed(2);

                    // Format based on baseUnit
                    if (baseUnit === 'bytes') {
                      formattedValue = formatBytes(measurement.value); // Format bytes appropriately
                    } else if (baseUnit === 'ns') {
                      formattedValue = formatTime(measurement.value); // Format nanoseconds as time
                    }

                    return (
                      <li key={index}>
                        <strong>{measurement.statistic}</strong>: {formattedValue}{' '}
                        {(baseUnit !== 'bytes' && baseUnit !== 'ns') && baseUnit}
                      </li>
                    );
                  })}
                </ul>
              </ListGroup.Item>
            )}
          </ListGroup>
        </Card.Body>
      </Card>
    );
  };

  // Helper function to render system health information
 // Helper function to render system health information
const renderHealthData = () => {
  if (!healthData) return null;

  const { status, components } = healthData;

  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>System Health</Card.Title>
        <ListGroup variant="flush">
          {/* Overall Status */}
          <ListGroup.Item>
            <strong>Overall Status:</strong>{' '}
            <span className={`text-${status === 'UP' ? 'success' : 'danger'}`}>{status || 'N/A'}</span>
          </ListGroup.Item>
  
          {/* Iterate through each health component */}
          {components && (
            <>
              {Object.entries(components).map(([key, detail]) => (
                <ListGroup.Item key={key}>
                  <Row>
                    <Col md={3}>
                      <strong>{key}:</strong>{' '}
                      <span className={`text-${detail.status === 'UP' ? 'success' : 'danger'}`}>{detail.status}</span>
                    </Col>
                    <Col md={9}>
                      {detail.details && (
                        <ListGroup variant="flush">
                          {Object.entries(detail.details).map(([subKey, value]) => (
                            <ListGroup.Item key={subKey} className="pl-0">
                              <strong>{subKey}:</strong>{' '}
                              {typeof value === 'number' && (subKey === 'total' || subKey === 'free' || subKey === 'threshold')
                                ? formatBytes(value) // Format bytes if it's a size field
                                : typeof value === 'boolean'
                                ? value.toString() // Convert boolean true/false to string
                                : value}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
  
};


  // Helper function to render errors from the server response
  const renderError = () => {
    if (!error) return null;

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Error</Card.Title>
          <Alert variant="danger">
            {error}
          </Alert>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Container className="mt-5">
      <h1>Monitoring Metrics</h1>

      {healthLoading ? (
        <Spinner animation="border" role="status" className="d-block mx-auto">
          <span className="visually-hidden">Loading health data...</span>
        </Spinner>
      ) : (
        <>
          {/* System Health Information */}
          {renderHealthData()}
        </>
      )}

      {loading ? (
        <Spinner animation="border" role="status" className="d-block mx-auto">
          <span className="visually-hidden">Loading metrics list...</span>
        </Spinner>
      ) : (
        <>
          {renderError()}
          <Row>
            {/* Left column: list of metrics */}
            <Col md={6}>
              <h3>Available Metrics</h3>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {metrics.map((metric, index) => (
                  <li key={index}>
                    <Button
                      variant="link"
                      onClick={() => handleMetricClick(metric)}
                      style={{ padding: 0 }}
                    >
                      {metric}
                    </Button>
                  </li>
                ))}
              </ul>
            </Col>

            {/* Right column: selected metric details */}
            <Col md={6}>
              <h3>Metric Details</h3>
              {metricLoading ? (
                <Spinner animation="border" role="status" className="d-block mx-auto">
                  <span className="visually-hidden">Loading metric...</span>
                </Spinner>
              ) : selectedMetric && metricData ? (
                <>
                  {/* Beautified Key Metrics */}
                  {renderBeautifiedMetrics()}

                  {/* Raw JSON Data */}
                  <Card className="mt-4">
                    <Card.Body>
                      <Card.Title>Raw JSON Data</Card.Title>
                      <pre>{JSON.stringify(metricData, null, 2)}</pre>
                    </Card.Body>
                  </Card>
                </>
              ) : (
                <p>Select a metric to view its details</p>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default ActuatorMetric;
