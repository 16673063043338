// src/util/fetchWithAuth.js
const fetchWithAuth = async (url, options = {}) => {
  const token = sessionStorage.getItem('jwt');

  // Ensure we have headers, and add the Authorization header
  const headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json' // Ensure Content-Type is set for all requests
  };

  try {
      // Fetch with options, spreading any other options passed in
      const response = await fetch(url, {
          ...options,
          headers,
      });

      if (!response.ok) {

          window.location.href = '/login'; // Redirect to login page if unauthorized
        
          if (response.status === 401) {
              // Unauthorized access - expired token
              console.error('Unauthorized access - possibly invalid or expired token');
              sessionStorage.removeItem('jwt'); // Clear the expired token

              return { status: 401, message: 'Unauthorized' }; // Return specific status for 401
          } 
          else if (response.status === 403) {
              console.warn('Access forbidden: You do not have permission to access this resource.');
              return { status: 403, message: 'Forbidden' };
          } 
          else {
              console.warn(`Request failed with status ${response.status}`);
              return { status: response.status, message: 'Request failed' };
          }
      }

      return response;
  } catch (error) {
      console.error('Fetch error:', error);
      return null;
  }
};

export default fetchWithAuth;
